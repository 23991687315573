{
    "marketplace": {
        "categories": {
            "all": "All categories",
            "content_and_logistics": "Content and logistics",
            "installed": "Installed modules",
            "integration": "Integration",
            "live": "Live",
            "networking": "Networking"
        },
        "critical_updates": {
            "notification": {
                "dismiss_button": "Apply all fixes",
                "message": "Some of the modules in this workspace have bug fixes available",
                "title": "Important fixes available"
            }
        },
        "empty_result": "No results were found for {0} in {1}",
        "free_module": "Free module",
        "install": {
            "completed": "Installation completed",
            "confirm": "Install",
            "confirm_title": "Install",
            "continue": "Do you want to install {0} on this workspace?",
            "dependencies": "In order to install this module, additional modules will be installed or updated. Please review the list below.",
            "major": "This install requires major changes in the way this module works and looks. Be careful when updating a live workspace as it may impact the way users interact with your app.",
            "package_note": "This might take a while",
            "performing": "Installing",
            "preparing": "Preparing installation"
        },
        "need_refresh": "Backstage will now reload to apply the changes.",
        "package": {
            "all_changelogs": "See all",
            "already_installed": "This module has been installed in this workspace.",
            "beta": "Beta",
            "beta_sign_up": "Sign up for beta",
            "changelog": "What's new?",
            "contact_us": "Contact us",
            "critical": "Bug fix available",
            "dependencies": "Dependencies",
            "deprecated": "Deprecated",
            "install": "Install",
            "installed": "Installed",
            "libraries": "Libraries",
            "libraries_warn": "This page is for advanced users only. If you are not sure what you are doing, please contact the support.",
            "modules": "Modules",
            "paid_alert": "This is a paid module, part of the advanced modules package. For pricing and billing information please contact your account manager.",
            "paid_alert_add_on": "This is a paid add-on module. For pricing and billing information please contact your account manager.",
            "paid_alert_support": "This is a paid module. Please check with the account manager if the client is authorized to use this functionality before installing.",
            "paid_info": "Unlock this paid module! Contact us for pricing and activation.",
            "see_api_doc": "API documentation",
            "see_doc": "See documentation",
            "uninstall": "Uninstall",
            "update": "Update",
            "update_all": "Update all",
            "update_pacakge": "This update has major changes in the way this module works and looks. You're about to update it on an workspace that is live. Are you sure you want to update {0}?",
            "update_to": "Update to {0}"
        },
        "paid_add_on": "Premium paid module",
        "paid_module": "Paid module",
        "see_more": "See more",
        "title": "Marketplace",
        "uninstall": {
            "completed": "Removal completed",
            "confirm": "Remove",
            "confirm_title": "Remove module",
            "continue": "Do you want to permanently remove {0} from this workspace?",
            "dependencies": "In order to remove this module, additional modules will be impacted. Please review the list below.",
            "major": "This removal requires major changes in the way this module works and looks. Be careful when updating a live workspace as it may impact the way users interact with your app.",
            "package_note": "This might take a while",
            "performing": "Removing",
            "preparing": "Preparing removal"
        },
        "updatable_packages": "{n} update available | {n} updates available",
        "update": {
            "completed": "Update completed",
            "confirm": "Update",
            "confirm_title": "Update",
            "continue": "Do you want to update {0} on this workspace?",
            "dependencies": "In order to update this module, additional modules will be installed or updated. Please review the list below.",
            "major": "This update will significantly change the way this module works and looks. Be careful when updating a live workspace as it may impact the way users interact with your app.",
            "package_note": "This might take a while",
            "performing": "Updating",
            "preparing": "Preparing update"
        },
        "update_all": {
            "completed": "Update completed",
            "confirm": "Update all",
            "confirm_title": "Update all",
            "continue": "The following modules will be updated to their latest versions. Please note that some updates may change the way a module works or looks.",
            "major": "This update will significantly change the way this module works and looks. Be careful when updating a live workspace as it may impact the way users interact with your app.",
            "package_note": "This might take a while",
            "performing": "Updating",
            "preparing": "Preparing update"
        }
    }
}
