/** @module URLs */

// Utils
import { isEmpty, isString } from 'lodash';
import { extractUrlFromIframe } from 'shared/libs/utils/dom';
import { compileWithObject } from 'shared/libs/utils/string';

// Constants
import {
    IS_AWS,
    ADDRESS_PORT_REGEX,
    API_BASE_PATH,
    API_SUBDOMAIN,
    APP_SUBDOMAIN,
    DEFAULT_APP_ROOT_DOMAIN,
    PRODUCTION_APP_ROOT_DOMAIN,
    ROUTE_EVENT_API_DOC,
    ROUTE_ORG_API_DOC,
    ROUTE_PACKAGE_API_DOC,
    DEV_APP_SUBDOMAIN,
    PUBLIC_REGISTRATION_URL
} from 'libs/utils/constants';

const INFRA_ROUTES = [
    '/apps',
    '/content-hubs',
    '/login',
    '/orgs',
    '/users',
    '/webinars'
];

const KNOWN_APPS = [
    'event',
    'events',
    'live-session',
    'studio',
    'live-display',
    'content-hub'
];

/**
 * Tries to extract the event ID from the location URL.
 *
 * @param {string} [href=window.location.href] the url from which extract the EID
 *
 * @returns {string|null} the current event ID or null.
 */
export function getCurrentEventIdFromUrl(href = window.location.href) {
    const url = new URL(href);
    const [, context, eid] = url.pathname.split('/');
    const hasContext = KNOWN_APPS.includes(context);

    if (hasContext && eid?.length) {
        return eid;
    }

    return null;
}

/**
 * Encodes an object to query string
 *
 * @param {object} obj the object to encode
 *
 * @returns {string} a string containing parameters suitable for URLs
 */
export function objectToQueryString(obj) {
    const enc = encodeURIComponent;

    return Object.keys(obj).map(k => `${enc(k)}=${enc(obj[k])}`).join('&');
}

/**
 * Given a node to test, check whether the passed node matches the current location.
 *
 * @param {String} node to test
 *
 * @returns {Boolean} true if the passed node corresponds to the current location
 */
export function isCurrentNode(node) {
    const subdomainSplit = window.location.host.split(`-${APP_SUBDOMAIN}`);
    if (subdomainSplit.length === 2) {
        const currentNode = subdomainSplit[0];
        if (currentNode === node) {
            return true;
        }
    }
    return false;
}

/**
 * Checks if host is a local hostname (localhost or *.local) or an IP
 *
 * @param {String} [host=window.location.host] the host to check, if not given, current location is used.
 *
 * @returns {Boolean} whether the given host is local or a domain name
 */
export function isHostLocal(host = window.location.host) {
    return ['localhost', '.local'].some(h => host.includes(h));
}

/**
 * Returns the main backstage instance URL
 *
 * @param {String} [path='/'] URL path
 * @param {String} [query=''] URL query
 * @param {String} [scheme=''] an optional protocol
 *
 * @returns {String} main backstage instance URL
 *
 * @deprecated use services.router.getHomeUrl
 */
export function getHomeUrl(path, query, scheme) {
    return getBackstageURL(APP_SUBDOMAIN, path, query, scheme);
}

/**
 * Given an optional host (or node name, URL, or IP address), path, and query string,
 * this method returns the proper *full* URL to the backstage resource.
 *
 * Note 1: if no scheme is provided the default will be `//`
 * Note 2: if no host is provided the current location host will be used
 * Note 3: the returned URL contains a trailing `/`
 *
 * @example
 * getBackstageURL(); // => '//eustaging7.backstage.spotme.com/'
 * getBackstageURL('eustaging7'); // => '//eustaging7.backstage.spotme.com/'
 * getBackstageURL(undefined, 'somewhere', 'some=query'); // => '//eustaging7.backstage.spotme.com/somewhere?some=query'
 * getBackstageURL('eustaging7', undefined, undefined, 'https'); // => 'https://eustaging7.backstage.spotme.com/'
 *
 * @param {String} [host] an optional hostname to force the URL for
 * @param {String} [path='/'] URL path
 * @param {String} [query=''] URL query
 * @param {String} [scheme] an optional protocol
 *
 * @returns {String} the top level application instance URL
 */
export function getBackstageURL(host, path = '/', query = '', scheme = '') {
    if (query.length && !query.startsWith('?')) {
        query = `?${query}`;
    }

    if (!path.startsWith('/')) {
        path = `/${path}`;
    }

    path = `${path}${query}`;

    if (!isEmpty(scheme) && !scheme.endsWith(':')) {
        scheme = `${scheme}:`;
    }

    return `${scheme}//${getBackstageDomain(host)}${path}`;
}

/**
 * Gets the fully qualified domain name of the current or given host.
 * This method supports hostnames, URLs, nodenames, or IP addresses.
 *
 * NOTE: if port is part of the given host it will be kept
 *
 * @example
 * // All following examples resolves to "eustaging7.backstage.spotme.com"
 * getBackstageDomain();
 * getBackstageDomain('eustaging7');
 * getBackstageDomain('http://eustaging7.4pax.com');
 * getBackstageDomain('eustaging7.4pax.com');
 *
 * // Port is kept
 * getBackstageDomain('http://eustaging7.4pax.com:3000'); // => 'eustaging7.backstage.spotme.com:3000'
 *
 * // IP are not resolved
 * getBackstageDomain('192.168.1.10:3333'); // => '192.168.1.10:3333'
 *
 * @param {String} [host=window.location.host] the hostname, nodename, or IP to use, if not given, current location host is used.
 *
 * @returns {String} the fully qualified domain name
 */
export function getBackstageDomain(host = window.location.host) {
    let { port, domain, subdomain } = parseHost(host);

    domain = getTopLevelDomain(host);

    if (subdomain === DEV_APP_SUBDOMAIN || isEmpty(subdomain)) {
        subdomain = APP_SUBDOMAIN;
    }

    if (subdomain !== APP_SUBDOMAIN) {
        subdomain = `${subdomain}.${APP_SUBDOMAIN}`;
    }

    return `${subdomain}${domain}${port.length ? `:${port}` : ''}`;
}

/**
 * Gets backstage cluster domain.
 *
 * @example
 * getBackstageCookieDomain('https://backstage.spotme.com'); // => 'backstage.spotme.com'
 * getBackstageCookieDomain('https://euadmin4.backstage.spotme.com'); // => 'backstage.spotme.com'
 * getBackstageCookieDomain('https://backstage.spotme-stage.com'); // => 'backstage.spotme-stage.com'
 *
 * @param {string} [host]
 * @returns {string}
 */
export function getBackstageCookieDomain(host = window.location.host) {
    return `${APP_SUBDOMAIN}${getTopLevelDomain(host)}`;
}

/**
 * Gets backstage infra domain.
 *
 * @example
 * getInfraCookieDomain('https://backstage.spotme.com'); // => '.spotme.com'
 * getInfraCookieDomain('https://euadmin4.backstage.spotme.com'); // => '.spotme.com'
 * getInfraCookieDomain('https://backstage.spotme-stage.com'); // => '.spotme-stage.com'
 *
 * @param {string} [host]
 * @returns {string}
 */
export function getInfraCookieDomain(host = window.location.host) {
    return getTopLevelDomain(host);
}

/**
 * Gets the host top and second level domain.
 *
 * NOTE: `host` parameter must not be an URL but a pure hostname.
 *
 * @example
 * getTopLevelDomain('localhost.backstage.4pax.com'); // => '.4pax.com'
 * getTopLevelDomain('eustaging.4pax.com'); // => '.spotme.com'
 * getTopLevelDomain('eustaging.sub1.sub2.4pax.com'); // => '.4pax.com'
 *
 * @param {String} [host=window.location.host] the hostname to use to get the root domain
 *
 * @returns {String} the host top and second and second level domain
 */
export function getTopLevelDomain(host = window.location.host) {
    if (isHostLocal(host)) {
        return DEFAULT_APP_ROOT_DOMAIN;
    }

    const parts = parseHost(host);
    const domain = `.${parts.domain}.${parts.tld}`;

    if (domain === DEFAULT_APP_ROOT_DOMAIN) {
        return PRODUCTION_APP_ROOT_DOMAIN;
    }

    return domain;
}

/**
 * Get a node name from an URL for the _router.
 * Node URLs have the format https:/{something}.4pax.com, protocol is optional
 *
 * @param {String} nodeUrl
 *
 * @return {String} the node name
 */
export function getNodeName(nodeUrl) {
    const { subdomain } = parseHost(nodeUrl);
    // For AWS events, the nodeUrl may have been populated with an API URL of the form e.g. https://eu-api.spotme.com
    // so the node name has to be extracted from the subdomain
    return subdomain.replace('-api', '');
}

/**
 * Given an host this method returns the host parts.
 *
 * NOTE 1: this method is port safe, meaning that if the hostname comes with `:<portnumber>` it's okay.
 * NOTE 2: this method is URL safe, meaning that if the given host is an URL (protocol and everything) it's okay.
 * NOTE 3: this method is not IP safe
 *
 * @param {String} host the hostname or an URL to split into parts
 *
 * @returns {Hostparts} an object with all host parts
 */
export function parseHost(host) {
    // Port probing
    const portMatch = host.match(ADDRESS_PORT_REGEX);
    let port = '';

    if (portMatch) {
        port = portMatch.pop();
        host = host.replace(ADDRESS_PORT_REGEX, '');
    } else if (window.BSTG.env === 'dev') {
        port = window.location.port;
    }

    // Sanitize URLs
    if (host.match(/^(.*:)?\/\/?/)) {
        if (host.startsWith('//')) {
            host = `http:${host}`;
        }
        const url = new URL(host);

        host = url.host;
        port = port.length ? port : url.port;
    }

    let subdomain;

    if (!host.match(/\./)) {
        subdomain = host;
        host = `${subdomain}${DEFAULT_APP_ROOT_DOMAIN}`;
    }

    const regexParse = new RegExp('([a-z\-0-9]{2,63})\.([a-z\.]{2,5})$');
    const [, domain, tld] = regexParse.exec(host);

    if (subdomain !== APP_SUBDOMAIN) {
        subdomain = host
            .replace(`${APP_SUBDOMAIN}.`, '')
            .replace(`${domain}.${tld}`, '')
            .slice(0, -1);
    }

    return { domain, tld, port, subdomain };
}

/**
 * Gets the host URL for API requests based on the local environment.
 *
 * @returns {String} the API host URL
 */
export function getBackendURL(node = null) {
    if (!IS_AWS && (window.BSTG.env === 'dev')) {
        return getBackstageURL().replace(/\/$/, '');
    } else if (node) {
        if (IS_AWS) {
            // e.g. https://eu-api.spotme.com
            return `https://${node}-${API_SUBDOMAIN}${PRODUCTION_APP_ROOT_DOMAIN}`;
        }
        // e.g. https://euprod20.backstage.spotme.com
        return `https://${node}.${APP_SUBDOMAIN}${PRODUCTION_APP_ROOT_DOMAIN}`;
    } else {
        const subdomainSplit = window.location.host.split('.backstage');
        if (IS_AWS && (subdomainSplit.length === 2)) {
            node = subdomainSplit[0];
            // e.g. https://eu-api.spotme.com
            return `https://${node}-${API_SUBDOMAIN}${PRODUCTION_APP_ROOT_DOMAIN}`;
        }
        // e.g. https://api.spotme.com
        return `https://${API_SUBDOMAIN}${PRODUCTION_APP_ROOT_DOMAIN}`;
    }
}

/**
 * Gets developer documentation URL based on the local environment.
 * If context is not provided the documentation base URL is returned.
 *
 * @param {Object} [context] the context for which getting the url for
 * @param {String} [context.eventId] must be provided for event documentation URL
 * @param {String} [context.orgName] must be provided for org documentation URL
 * @param {String} [context.pkgName] must be provided for package documentation URL
 * @param {String} [context.version] must be provided for package documentation URL
 *
 * @returns {String} the Orgaization's specific developer documentation URL
 */
export function getDeveloperDocURL(context) {
    let path = '';

    if (context.eventId) {
        path = ROUTE_EVENT_API_DOC.replace(':eid?', context.eventId);
    }

    if (context.orgName) {
        path = ROUTE_ORG_API_DOC.replace(':orgName?', context.orgName);
    }

    if (context.pkgName) {
        path = ROUTE_PACKAGE_API_DOC
            .replace(':pkgName?', context.pkgName)
            .replace(':version?', context.version);
    }

    let host = `https://developer${PRODUCTION_APP_ROOT_DOMAIN}/docs`;

    if (window.BSTG.env === 'dev') {
        const bstgDomain = getBackstageDomain().replace('localhost', 'developer');

        host = `https://${bstgDomain}/docs`;
    }

    return `${host}${path}`;
}

/**
 * transform an url query string into an object
 * @param queryString
 * @return {Object} the query as an object
 */
export function getQueryObj(queryString = window.location.search) {
    const result = {};
    try {
        if (queryString[0] === '?') {
            queryString = queryString.substring(1);
        }
        const params = new URLSearchParams(queryString);
        for (const [key, value] of params) {
            result[key] = value;
        }
    } catch (error) {
        console.error('[utils/url] query string to object has failed', error);
    }
    return result;
}

/**
 * Build URL by interpolating given key value pairs
 *
 * @param {String} url
 * @param {Object} [replacements={}]
 * @returns {String}
 */
export function interpolateUrl(url, replacements = {}) {
    return compileWithObject(url, replacements);
}

/**
 * Given the name of the event's app and a name of the path
 * this method returns the URL for the webapp registration page
 *
 * @param {string} branding the event's branded app name
 * @param {string} pathName the name of the path
 * @param {boolean} [noCache] whether to return a non-cacheable version of the URL or not
 * @param {string} [whiteLabelDomain] the event's custom domain URL
 *
 * @returns {string} the URL of the webapp registration page
 */
export function getWebappRegistrationUrl(branding, pathName, noCache, whiteLabelDomain) {
    const root = whiteLabelDomain
        ? `https://${whiteLabelDomain}/login/{{branding}}/{{pathName}}`
        : PUBLIC_REGISTRATION_URL;

    let url = interpolateUrl(root, { branding, pathName });

    if (noCache) {
        url = appendCacheBuster(url, 'bustCache');
    }
    return url;
}

/**
 * Given the eid of a template
 * this method returns the URL for the webapp registration page
 *
 * @param {string} eid the id of the template
 *
 * @returns {string} the URL of the webapp registration page
 */
export function getWebappRegistrationUrlForTemplate(eid) {
    const { host } = new URL(PUBLIC_REGISTRATION_URL);
    return `https://${host}/login/templates_registration/${eid}`;
}

/**
 * Checks if the give string is a valid URL or not.
 *
 * @param {string} string the string to check
 *
 * @returns {boolean} true if it's an URL false otherwise
 */
export function isUrl(string) {
    if (!isString(string)) return false;

    let isUrl = true;

    // Same client's protocol URLs
    if (string.match(/^\/\/.+/)) {
        string = `https:${string}`;
    }

    try {
        new URL(string);
    } catch (error) {
        isUrl = false;
    }

    return isUrl;
}

/**
 * Transforms the given path into a URL object suitable
 * for parts manipulation.
 *
 * @note If the path is not a string undefined is returned;
 *
 * @param {string} path the path to transform
 *
 * @returns {URL|undefined} an URL object representing the path
 */
export function pathToUrl(path) {
    if (!isString(path)) return;

    const fakeUrl = `fake:${path}`;
    return new URL(fakeUrl);
}

/**
 * Transforms the given string into a URL object
 *
 * @note If `string` is not a string undefined is returned;
 *
 * @param {string} string the string to transform into a URL object
 *
 * @returns {URL|undefined}
 */
export function stringToUrl(string) {
    if (!isString(string)) return;

    if (isUrl(string)) {
        // Same client's protocol URLs
        if (string.match(/^\/\/.+/)) {
            string = `https:${string}`;
        }

        return new URL(string);
    }
    return pathToUrl(string);
}

/**
 * Appends a cache buster parameter to the given string, that may
 * be a path or a url.
 *
 * @note this method is safe for Blob URLs
 *
 * @param {string} string the url or path to append the buster to.
 * @param {string} [buster='_c'] the name of the cache buster parameter
 *
 * @return {string} the url enriched with a cache buster parameter
 */
export function appendCacheBuster(string, buster = '_c') {
    if (!isString(string)) return string;
    if (string.startsWith('blob:')) return string;

    const url = stringToUrl(string);
    if (!url) return string;

    url.searchParams.append(buster, Date.now().toString());
    const isPath = !isUrl(string);

    return isPath ? `${url.pathname}${url.search}` : url.toString();
}

/**
 * Returns the infra backstage instance root domain
 *
 * @returns {string} the infra root domain (no region)
 */
export function getBackstageRootDomain() {
    return getBackstageDomain(''); // I.E. backstage.spotme.com
}

/**
 * Checks if the current instance is the infra root one
 *
 * @returns {boolean}
 */
export function isInfra() {
    return getBackstageRootDomain() === getBackstageDomain();
}

/**
 * Checks if this is a valid video url
 * @param {String} url
 * @returns {Boolean}
 */
export function isVideoUrl(url) {
    let fullUrl = url;

    // build a fake url if the url is relative
    if (!url?.toString()?.startsWith('http')) {
        fullUrl = `file:///${url}`;
    }

    const path = new URL(fullUrl).pathname;

    return path.endsWith('m3u8') || path.endsWith('mpd') || path.endsWith('mp4');
}

/**
 * Determines if the given url should be served by Infa (URL)
 * or Cluster (false)
 *
 * @param {string} url the url to check
 *
 * @returns {false|URL} false or the URL object
 */
export function isInfraUrl(url) {
    try {
        const currentUrl = new URL(url);
        const currentPath = currentUrl.pathname;
        if (currentPath === '/' || INFRA_ROUTES.some(path => currentPath.startsWith(path))) {

            return currentUrl;
        }
        return false;

    } catch (error) {
        console.warning('[App] Could not determine current URL', error.message);
        return false;
    }
}

/**
 * Compiles the correct HREF for import/export purposes
 *
 * @param {string} path the path of the import
 * @param {string} eventId the ID of the event
 * @param {string} [basePath] the base path of the import/export url
 *
 * @returns {string} the href for the export or the import
 */
export function getImportExportHref(path, eventId, basePath = `${API_BASE_PATH}/events`) {
    if (!path.startsWith('/')) {
        path = `/${path}`;
    }

    if (!basePath.startsWith('/')) {
        basePath = `/${basePath}`;
    }

    return interpolateUrl(`${basePath}/{{eventId}}${path}`, { eventId });
}

/**
 * Checks if the url starts with the right protocol or subdomain
 *
 * @param {string} string the url to check
 * @param {string} url the url to check against
 *
 * @returns {boolean}
 */
export function urlStartsWith(string, url) {
    return string.startsWith(`https://${url}`) || string.startsWith(`https://www.${url}`);
}

/**
 * Tries to create the embed URL of the given input url
 *
 * @param {string} input the URL of the service to embed
 *
 * @returns {string} the embed URL
 */
export function transformToEmbeddedUrls(input) {
    if (/https:\/\/([\w-]+\.)?figma.com\/(file|proto)\/([0-9a-zA-Z]{22,128})(?:\/.*)?$/.test(input)) {
        return `https://www.figma.com/embed?embed_host=astra&url=${input}`;
    }

    input = extractUrlFromIframe(input);
    const url = new URL(input);

    if (urlStartsWith(url.href, 'youtube.com')) {
        url.href = url.href.replace('youtube.com/watch?v=', 'youtube.com/embed/');
    } else if (urlStartsWith(url.href, 'youtu.be')) {
        url.href = url.href.replace('youtu.be/', 'youtube.com/embed/');
    } else if (urlStartsWith(url.href, 'miro.com')) {
        url.href = url.href.replace('miro.com/app/board/', 'miro.com/app/live-embed/');
    }

    return url.toString();
}
