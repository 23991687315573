{
    "assisted_check_in": {
        "form": {
            "checked_in_attendees": "List of checked in attendees",
            "metadata_to_display": "Metadata to display on the list (max. 2 items)",
            "metadata_to_search_by": "Metadata to search by",
            "metadata_verification_screen": "Metadata to be displayed on the check-in verification screen",
            "non_checked_in_attendees": "List of not checked in attendees",
            "scan_qr_code": "Scan QR Code",
            "search": "Search",
            "walk_ins": "Walk-ins",
            "walk_ins_sessions": "Select sessions that allow walk-ins",
            "walk_ins_sessions_placeholder": "Choose sessions"
        },
        "intro": "To grant specific users the ability to scan session attendances, you must enable the setting on their user profile page.",
        "section_intros": {
            "check_in_intro": "Enable at least one option that host / hostess will use to check-in attendees into sessions.",
            "dashboard_intro": "Set up the view of the dashboard"
        },
        "sections": {
            "check_in": "Check-in",
            "dashboard": "Dashboard",
            "pin_codes": "PIN Codes",
            "qr_codes": "QR Codes"
        }
    },
    "self_check_in": {
        "messages": {
            "first_time_generated": "Pin codes generated for all sessions.",
            "prompt_deletes": "Are you sure you want to delete these QR codes?",
            "regenerate_success": "Pin codes regenerated for all sessions.",
            "save_failure": "Error regenerating pin codes"
        },
        "pin_codes": {
            "form": {
                "force_regenerate_btn_txt": "Regenerate all codes",
                "force_regenerate_label": "Force regenerate all pin codes for all sessions",
                "pin_codes": "PIN Codes",
                "regenerated_btn_txt": "Codes successfully regenerated"
            },
            "intro": "By enabling this feature, PIN codes will be automatically generated and available on each backstage session page. The session host can then provide these codes to attendees for self-check-in."
        },
        "qr_codes": {
            "actions": {
                "export_qrs": "Export QR Codes",
                "export_xls": "Export XLS",
                "import_xls": "Import XLS",
                "new_qr": "New QR Code"
            },
            "list": {
                "delete": "Delete selected QR codes",
                "scans": "Scans",
                "title": "Title"
            }
        },
        "sections": {
            "pin_codes": "PIN Codes",
            "qr_codes": "QR Codes"
        }
    },
    "session_check_in": {
        "messages": {
            "save_failure": "Could not save the settings. Try again later or contact the support.",
            "save_success": "Settings successfully updated."
        }
    }
}
