{
    "live_sessions": {
        "action_failures": {
            "change_camera": "Failed to change camera",
            "change_mic": "Failed to change microphone",
            "start_camera": "Failed to start camera",
            "start_mic": "Failed to start microphone",
            "stop_camera": "Failed to stop camera",
            "stop_mic": "Failed to stop microphone",
            "try_again": "Please try again or use another device. If the issue persists, refresh the page or restart the browser."
        },
        "actions": {
            "3rd_finish_broadcast": "Finish broadcast",
            "3rd_start_broadcast": "Start broadcast",
            "3rd_stop_broadcast": "Reset",
            "add_video": "Add videos",
            "apply_virtual_background": "Background",
            "blurred_background_info": "Blurred background may use additional CPU resources. Please make sure that your computer meets the hardware requirements <a href='{0}' target='_blank'>listed here</a>.",
            "delete": "Delete live session",
            "exit_fullscreen": "Exit fullscreen",
            "finish_broadcast": "Finish stream",
            "force_mute": "Stop sharing audio to Live",
            "force_unmute": "Share audio to Live",
            "give_feedback": "Give feedback",
            "go_fullscreen": "Full screen",
            "invite": "Invite",
            "keep_video_off": "Keep video off",
            "kick_out": "Kick out",
            "kick_speaker": "Kick out speaker",
            "leave_room": "Leave the room",
            "live_sessions": "Live sessions",
            "mute": "Mute",
            "noise_cancelling": "Background noise removal",
            "noise_cancelling_info": "Noise removal may use additional CPU resources. Please make sure that your computer meets the hardware requirements <a href='{0}' target='_blank'>listed here</a>.",
            "noise_cancelling_unsupported": "Background noise removal is not supported on your system",
            "open_room": "Open room",
            "preview": "Start preview",
            "publish": "Publish",
            "rejoin": "Rejoin",
            "release": "Release",
            "rename": "Rename",
            "request_go_live": "Request to go live",
            "request_go_live_audio": "Request to go live with only audio",
            "save": "Save",
            "share_screen": "Share screen",
            "start_audio": "Share audio",
            "start_broadcast": "Go live",
            "start_video": "Start video",
            "stay_muted": "Stay muted",
            "stop_audio": "Stop sharing audio",
            "stop_broadcast": "End stream",
            "stop_preview": "Stop preview",
            "stop_share_screen": "Stop sharing",
            "stop_video": "Stop video",
            "unmute": "Unmute",
            "unpublish": "Unpublish",
            "waiting_for_reservation": "We are preparing your stream. You will be able to go live in a minute. Please wait."
        },
        "analytics": {
            "title": "Analytics"
        },
        "banner": {
            "audio_not_broadcasted_while_video_on": "While the video is on, your audio is not live",
            "muted": "You are muted, unmute yourself so others can hear you",
            "notifications": {
                "muted_by_moderator": "{0} muted you",
                "own_camera_added_to_broadcast_by_moderator": "{0} added you to the stage",
                "own_camera_removed_from_broadcast_by_moderator": "{0} removed you from the stage",
                "own_camera_stopped_by_moderator": "{0} stopped your camera",
                "own_screen_added_to_broadcast_by_moderator": "{0} added your screen to the stage",
                "own_screen_removed_from_broadcast_by_moderator": "{0} removed your screen from the stage",
                "renamed_by_moderator": "{0} renamed you",
                "speaker_request_rejected": "The moderator has denied your request to go live"
            },
            "subscribers_not_receiving_video": "You are not receiving video from some speakers."
        },
        "broadcast": {
            "change_layout_error_title": "Failed to apply changes",
            "change_request_failed": "Unexpected error. Please try again.",
            "conflicting_request": "Someone else has made changes at the same time.",
            "going_live": "Starting...",
            "retrieval_failed_body": "Please check your connection and refresh the page.",
            "retrieval_failed_title": "Failed to retrieve streams on stage"
        },
        "chat": {
            "check_icon_tooltip": "Connectivity test found no issues",
            "disabled_reasons": {
                "must_join_session": "Join the Studio session to chat with other speakers",
                "room_closed": "This room is now closed",
                "room_not_open": "Open the room to start the chat"
            },
            "info_icon_tooltip": "Connectivity test was skipped",
            "messages": {
                "adding_chat_message_failed": "Couldn't send your message. Please try again"
            },
            "new_message_prompt": "Write your message...",
            "new_message_received": "New message(s)",
            "send_new_message": "Send",
            "title": "Chat",
            "warning_icon_tooltip": "Connectivity test found issues"
        },
        "connection_quality_modal": {
            "list_p1": "Close unnecessary applications on your computer.",
            "list_p2": "Stop downloads, file sharing and other music and video streaming services.",
            "list_p3": "Turn off your camera and present using audio-only.",
            "list_p4": "If you are sharing your screen, ask someone else to do that for you.",
            "list_p5": "Review your firewall and VPN settings to allow Studio to work.",
            "list_p6": "Switch to a wired internet connection if possible, or come closer to your Wi-Fi router.",
            "publisher": {
                "list_header": "What can you do to fix this?",
                "subtitle": "We are receiving a lower quality video from you. This will have an impact on the quality of the video for viewers."
            },
            "subscriber": {
                "list_header": "What you can ask the speaker to do:",
                "subtitle": "We are receiving a lower quality video from this speaker. This will have an impact on the quality of the video for viewers."
            },
            "title": "Low video quality"
        },
        "connectivity": {
            "can_hear_sound": "Can you hear the sound?",
            "chrome_recommend": "We strongly recommend using Google Chrome.",
            "footer_title": "Tips and technical requirements",
            "headphones_recommend": "Use wired headphones over Bluetooth ones.",
            "intro": "Test the connectivity and quality of your setup to solve any possible issues ahead of time.",
            "loading_devices": "Detecting devices. Please wait...",
            "modal": {
                "general_bullet_1": "Switch to a wired internet connection, or come closer to your WI-FI router",
                "general_bullet_2": "Close any unnecessary applications on your computer",
                "general_bullet_3": "Stop any downloads, file sharing and other music and video streaming services",
                "general_header": "General",
                "studio_bullet_1": "Turn off your camera and present using audio-only",
                "studio_bullet_2": "If you are sharing a presentation, ask someone else to share it for you",
                "studio_header": "Studio",
                "subtitle": "What can you do to fix it?",
                "title": "Connectivity issue",
                "warning": "We are detecting a low video quality from your device. This will affect the quality of the video for your viewers."
            },
            "more_tips": "More tips and technical requirements",
            "no_devices_found": "We couldn’t find any devices. Allow the camera and microphone in your browser and refresh this page.",
            "please_wait": "Please wait a moment while we do a quick test. This test will take around 30 seconds.",
            "results": {
                "accordion_headers": {
                    "audio": "Microphone",
                    "connection": "Connection",
                    "connectivity": "Connectivity",
                    "speakers": "Speakers",
                    "speed": "Connection speed",
                    "stability": "Connection stability",
                    "video": "Webcam"
                },
                "audio_issue": "Studio was unable to capture any sound from your microphone. Try the following:<br><br><ul><li>Relaunch the test and select another microphone if available.</li><li>Switch to wired headphones, if you are using Bluetooth headphones.</li><li>On a Mac, adjust microphone input level in the system settings.</li></ul>",
                "connection_error": "Studio couldn’t connect at all with your device. This is likely caused by firewall settings, which might require additional network configuration by your IT admin.<br><br><a href='{0}' target='_blank'>Firewall configuration settings</a>",
                "connection_warning": "Studio couldn’t connect to all our services. This is likely caused by your ad blocker's settings, you can try disabling it or contact your IT admin.<br><br><a href='{0}' target='_blank'>Firewall configuration settings</a>",
                "enter_studio": "Enter room",
                "enter_studio_anyway": "Enter room anyway",
                "failure_reasons": {
                    "audio-not-received": "No audio packets received",
                    "connection-failed": "Connection could not be established",
                    "inaudible": "Inaudible sound",
                    "no-device-selected": "No device was selected",
                    "permission-denied": "Permission to access the device was denied",
                    "request-failed": "Request to start device failed",
                    "tcp-connection-failed": "TCP connection failed",
                    "tcp-ice-negotiation-failed": "TCP connection couldn't be setup, ICE negotiation failed",
                    "tcp-meeting-session-urls-not-initialized": "TCP test not properly set up",
                    "udp-connection-failed": "UDP connection failed",
                    "udp-ice-negotiation-failed": "UDP connection couldn't be setup, ICE negotiation failed",
                    "udp-meeting-session-urls-not-initialized": "UDP test not properly set up",
                    "unknown": "Issue detected",
                    "video-not-sent": "No video packets sent"
                },
                "issue_subtitle": "Review the issues and potential improvements below, then retry the test.",
                "issue_title": "There was an issue",
                "relaunch_test": "Relaunch test",
                "screenshare_issue": "Your screen could not be shared with Studio. Please check your Operating System permissions. It could also be caused by firewall settings, which might require additional network configuration by your IT admin.<br><br><a href='{0}' target='_blank'>Firewall configuration settings</a>",
                "speakers_issue": "You reported not being able to hear the sound. Try the following:<br><br><ul><li>Switch to wired headphones, if you are using Bluetooth headphones.</li><li>Adjust your speakers volume.</li></ul>",
                "speed_issue": "A slow connection can cause your video to appear pixelated and your audio to stutter. Try the following and relaunch the test:<br><br><ul><li>Try using a network cable instead, if you can.</li><li>If you can’t use network cable, move as close to the Wi-Fi router as you can.</li><li>Turn off your webcam.</li><li>Close all other apps and websites that are not being used.</li><li>Make sure other people and devices are not overusing the network.</li></ul>",
                "stability_issue": "An unstable connection can cause your video and audio to freeze. Try the following and relaunch the test:<br><br><ul><li>Try using a network cable instead instead of Wi-Fi, if you can.</li><li>If you can’t use network cable, move as close to the Wi-Fi router as you can.</li><li>Close all other apps and websites that are not being used.</li><li>Make sure other people are not overusing the network.</li><li><a href='{0}' target='_blank'>Review the system and network requirements</a>.</li></ul>",
                "success_subtitle": "You are ready to enter the room. Have a good show!",
                "success_title": "Everything is fine",
                "timeout_error": "The test is taking longer than expected. This is likely caused by slow internet connection, temporary internet hiccups or your firewall settings. Try running the test again.",
                "togglerText": "What can I do?",
                "video_issue": "Studio was unable to capture any video from your webcam. Try the following:<br><br><ul><li>Relaunch the test to select another camera if available.</li><li>Close any other apps that may be using your camera.</li><li>If you have a webcam cover, remove it.</li></ul>"
            },
            "running_test": "Running connectivity test...",
            "skip_test": "Skip test",
            "speed_recommend": "We recommend at least 15Mb/s of upload and download speed for optimal results.",
            "test_connectivity": "Test connectivity",
            "test_screenshare": "Select screen to test",
            "test_your_speed": "Test your speed.",
            "testing": "Testing...",
            "title": "Connectivity test",
            "usage_recommend": "We recommend stopping downloads, file sharing, and any other video or music streaming services when using Studio.",
            "wired_recommend": "A wired connection is recommended over Wi-Fi."
        },
        "controls": "Controls",
        "countdown": {
            "back_to_stage": "Back to stage in",
            "going_live": "Going live in"
        },
        "counters": {
            "claps": "Claps",
            "connection_level": {
                "bad": "Bad connection",
                "excellent": "Good connection",
                "good": "Good connection",
                "weak": "Weak connection"
            },
            "counter_tooltip": "There might be an issue. Click for help",
            "fps": "fps",
            "frame_rate": "Frame rate",
            "stream_time": "Stream time",
            "viewers": "Viewers",
            "viewers_waiting": "Viewers waiting",
            "vod_viewers": "VOD viewers"
        },
        "details": {
            "attending_in_person": "Attending in person",
            "attending_live_displays": "Live displays",
            "attending_remote": "Attending remotely",
            "hybrid": "Hybrid",
            "in_person": "In-Person",
            "moderator_link": "Moderator permalink",
            "remote": "Virtual",
            "speaker_link": "Speaker permalink",
            "start_time": "Start time",
            "status": "Status",
            "title": "Title",
            "type": "Type"
        },
        "devices": {
            "camera_inactive_header": "No video detected",
            "camera_inactive_text": "Restarting camera...",
            "camera_potentially_not_working_header": "No video detected",
            "camera_potentially_not_working_text": "There seems to be an issue with your camera.",
            "mic_inactive_header": "No audio detected",
            "mic_inactive_text": "Restarting microphone...",
            "mic_potentially_not_working_header": "No audio detected",
            "mic_potentially_not_working_text": "There seems to be an issue with your microphone.",
            "muted_by_hardware_header": "Your microphone is muted",
            "muted_by_hardware_text": "Please check your hardware and system settings."
        },
        "disabled": "disabled",
        "engage": "Drive more engagement from your audience",
        "export": {
            "advanced": "Advanced options for interactions export",
            "connectivity_report": "Connectivity Report",
            "download": "Download",
            "end_time": "Optional end time for exported analytics",
            "interactions_report": "Interactions Report",
            "progress": "generating export...",
            "start_time": "Optional start time for exported analytics"
        },
        "form": {
            "captions": {
                "in_person": "In-person attendees",
                "in_person_hint": "Once you have created the live session, additional settings will be available to set this up.",
                "intro_label": "Select the closed captions service. Once you have created the live session, additional settings will be available to set this up.",
                "label_ai": "AI Media",
                "label_wordly": "Wordly",
                "main_label": "Closed captions",
                "virtual_attendees": "Virtual attendees",
                "virtual_attendees_hint": "Select the closed captions service. Once you have created the live session, additional settings will be available to set this up."
            },
            "clapping": "Claps",
            "displays_hint": "Get everyone on-site engaged with Polls and Q&A by displaying them on a TV or a projector at the event venue. You will be able to configure additional live displays later.",
            "displays_main": "Live display",
            "height": "Height",
            "hive": "Hive streaming (eCDN)",
            "hive_KB": "https://support.spotme.com/hc/en-us/articles/29749606817555-SpotMe-partners-with-Hive-Streaming",
            "hive_hint": "Hive streaming optimizes the outbound bandwidth usage, increase live stream reliability and it automatically scale based on audience.",
            "hive_more_info": "More information",
            "hive_title": "Outbound bandwidth savings",
            "interactivity": "Interactivity settings",
            "interactivity_hint": "Drive more engagement from your audience through Q&A and polls.",
            "interactivity_main": "Interactivity",
            "interprefy": "Live interpretation",
            "interprefy_project_id": "Interprefy project ID",
            "is_compositing": "Use cloud compositing",
            "is_third_party": "Use third party provider",
            "live_captions_enabled": "Closed captions",
            "live_display_enabled": "Live display",
            "moderator_permalink": {
                "hint": "Moderators can manage Q&A and polls.",
                "label": "Moderator permalink"
            },
            "polls": "Polls",
            "polls_colours": "Word cloud colors",
            "polls_colours_hint": "Select three colors that will be applied to word cloud visualisation.",
            "polls_notifications_enabled": "Send notifications",
            "provider": "Streaming provider",
            "providers": {
                "spotme": "RTMPS",
                "spotme_studio": "SpotMe Studio",
                "streamless": "None (In person)",
                "third_party": "Embed"
            },
            "qna": "Q&A",
            "qna_anonymous": "Allow anonymous submissions",
            "qna_ask_on_vod": "Ability to ask questions",
            "qna_moderated": "Moderated",
            "qna_private": "Private Q&A",
            "qna_private_subtitle": "Questions will be visible only to the moderators and speakers.",
            "qna_upvoting": "Upvoting",
            "session_type": "Session type",
            "sessions": {
                "hybrid": "Hybrid",
                "hybrid_description": "Video, Q&A, Polls & Live displays",
                "hybrid_disclaimer": "Need help setting up hybrid event attendees correctly?",
                "hybrid_learn_more": "Learn more",
                "in_person": "In-Person",
                "in_person_description": "Q&A, Polls & Live displays",
                "virtual": "Virtual",
                "virtual_description": "Video, Q&A & Polls"
            },
            "speaker_permalink": {
                "hint": "Speakers can view approved questions and see polling results.",
                "label": "Speaker permalink"
            },
            "start_time": "Scheduled for",
            "targeting": "Poll notifications targeting",
            "targeting_hint": "Choose who among your audience will receive a notification to join the poll. This will be the only notification sent from the poll.",
            "title": "Live session name",
            "unchangeable_type": "You won't be able to change this setting later",
            "url": "Embed URL",
            "video": "Video",
            "webrtc_provider": "WebRTC provider",
            "webrtc_providers": {
                "chime": "AWS Chime",
                "vonage": "Vonage"
            },
            "who_is_watching": "Viewers list",
            "width": "Width"
        },
        "frame_rate_modal": {
            "footer": "More tips on troubleshooting while live streaming are available on our {0}.",
            "knowledge_base": "knowledge base",
            "list_header": "What can you do to fix this?",
            "list_p1": "Try restarting the the streaming software you are using.",
            "list_p2": "Check your internet connection, or try switching to another network if available.",
            "list_p3": "Use a hardwire (ethernet) connection to your router instead of WiFi.",
            "subtitle": "We are receiving a lower than recommended number of video frames (fps) from your streaming software. This will have an impact on the quality of the video for the viewers.",
            "title": "Low frame rate"
        },
        "ghost": {
            "support_is_here": "Support is inspecting",
            "support_joined": "Support has joined the session"
        },
        "info_container_card": {
            "room_full": {
                "msg": "The room has reached its full capacity, once another speaker leaves you will be able to enter the room.",
                "title": "The room is full",
                "try_again": "Try again",
                "try_again_secs": "Try again in {secs} seconds"
            },
            "waiting_room": {
                "msg": "The host hasn’t opened this room yet. Once it has been opened you will be able to join.",
                "title": "It’s almost time"
            }
        },
        "input": "RTMPS Endpoint {0}",
        "inputs": "Inputs",
        "list": {
            "messages": {
                "delete_error": "The selected item could not be removed as it has started already.",
                "prompt_deletes": "Are you sure you want to delete this live session? Once you delete a live session, it is not possible to retrieve it again.",
                "remove_error": "The selected item could not be removed."
            }
        },
        "live": "Live",
        "lower_third": {
            "accent_color": "Accent color",
            "create": "Create lower third",
            "name": "Name",
            "no_items_yet": "No lower third items",
            "small_title": "L.Third",
            "subtitle_text": "Subtitle text",
            "title": "Lower Third",
            "title_text": "Title text"
        },
        "messages": {
            "actions": {
                "cannot_add_camera_to_stage": "Stop {elementOnScreen} sharing to add more than {maxSpeakers} speakers to the stream.",
                "cannot_add_screenshare_to_stage": "Make sure that there are no more than {maxSpeakers} speakers (including the host) in the live broadcast. Then try to add the screen share again.",
                "kick_speaker": "Are you sure you want to kick <strong>{0}</strong> out?",
                "kick_speaker_live": "Are you sure you want to kick <strong>{0}</strong> out? This speaker is currently live on the stream.",
                "leave_room": "Are you sure you want to leave the room?",
                "request_go_live": "<strong>{0}</strong> is requesting to go live.",
                "request_go_live_audio_only": "<strong>{0}</strong> is requesting to go live with audio only.",
                "share_screen_modal_confirm": "Share screen",
                "share_screen_modal_text": "Are you sure you want to share the screen now? The slide share will be removed from the stage.",
                "share_screen_modal_title": "Share screen now",
                "start_video": "The host would like you to start your video.",
                "unmute": "The host would like you to unmute.",
                "unmute_screenshare": "The host would like you to unmute your screen share."
            },
            "adding_question_failed": "Adding question failed",
            "approving_failed": "Approving failed",
            "archiving_failed": "Archiving failed",
            "auto_stop": "We have noticed that no content has been streamed to our system in the past {0} minutes. As a result, this stream will be automatically ended and closed in 5 minutes. If you are still going to use this stream, please select {1}.",
            "auto_stop_title": "Important: Your stream is going to be ended.",
            "changing_qna_availability_failed": "Changing Q&A availability failed",
            "confirm_countdown": "When you go live, your {0} stream countdown will start.",
            "confirm_finish": "Are you sure you want to finish this broadcast?",
            "confirm_preview": "Are you sure you would like to start your stream preview? After you start the preview you will need to go live within {0}.",
            "confirm_restart": "This live stream has already been used. Starting it now will create a session, analytics from the previous session will be lost. Continue?",
            "confirm_start": "Are you sure you want to go live now?",
            "confirm_stop": "Are you sure you want to end the stream now? You will be able to publish it for replay later.",
            "confirm_stop_3rdparty": "Are you sure you want to reset the stream now? If you reset, you will lose any analytics.",
            "confirm_stop_preview": "Are you sure you want to stop the preview? This stream will go back to its initial state and can be reused.",
            "copy": "Click to copy",
            "create_vod": "Do you want to publish the stream?",
            "create_vod_message": "Make the stream available to be watched anytime on demand, via the live stream player.",
            "empty_question": "Please provide question text",
            "end_notification": "This stream will automatically shut down in less than {0} minutes!",
            "end_now": "End now",
            "end_now_3rdparty": "Reset now",
            "errors": {
                "connection": {
                    "1004": "Authentication error",
                    "1005": "Invalid Session ID",
                    "1006": "Connect Failed",
                    "1007": "Connect Rejected",
                    "1008": "Connect Time-out",
                    "1009": "Security Error",
                    "1010": "Not Connected",
                    "1011": "Invalid Parameter",
                    "1013": "Connection Failed",
                    "1014": "API Response Failure",
                    "1026": "Terms of Service Violation: Export Compliance",
                    "1027": "Connection limit exceeded.",
                    "2000": "Internal Error",
                    "2001": "Unexpected response"
                },
                "connection_failed": "Unable to connect to the stream. {0}",
                "error_title": "An error occurred",
                "moving_answered_failed": "Moving to answered failed",
                "moving_discussion_failed": "Moving to discussion failed",
                "no_suitable_devices": {
                    "text": "Have you allowed camera and microphone usage? If you have, check if other apps or websites are using your camera and microphone, close those and reload this page.",
                    "title_camera": "We can’t access your camera",
                    "title_mic": "We can’t access your microphone"
                },
                "publish_video": {
                    "subtitle": {
                        "camera_in_use": "Please verify that the camera is not in use by another application.",
                        "other": "Please refresh the page and try again in a few seconds.",
                        "permission_denied": "Please make sure the camera is not blocked by the Operating System or the browser."
                    },
                    "title": "Unable to publish camera video"
                },
                "speaker_changes": "Unable to apply speaker changes",
                "technical": {
                    "1004": "Authentication error. Check the error message on the server log for details. This error can result if you pass in an expired token when trying to connect to a session. It can also occur if you pass in an invalid token or API key. Make sure that you are generating the token using the current version of one of the OpenTok server SDKs.",
                    "1005": "Invalid Session ID. Make sure you generate the session ID using the current version of one of the OpenTok server SDKs.",
                    "1006": "Connect Failed. Unable to connect to the session. You may want to have the client check the network connection.",
                    "1010": "The client is not connected to the OpenTok session. Check that the client connects successfully before trying to publish. And check that the client has not disconnected before trying to publish.",
                    "1011": "Invalid Parameter. Check that you have passed valid parameter values into the method call.",
                    "1013": "WebRTC PeerConnection error. Try resubscribing to the stream or reconnecting to the session.",
                    "1026": "Terms of service violation: export compliance. See the Terms of Service.",
                    "1027": "Connection limit exceeded. The client tried to connect to a session that has exceeded limit for simultaneous connections.",
                    "1500": "Unable to Publish. This can be caused by the following:\nThe client's token does not have the role set to publish or moderator. Once the client has connected to the session, the capabilities property of the Session object lists the client's capabilities.\nThe end-user has denied access to the camera or microphone.\nThe page is loaded on HTTP in Chrome, which requires HTTPS to access the camera and microphone.",
                    "1520": "Unable to force disconnect. The client's token does not have the role set to moderator. Once the client has connected to the session, the capabilities property of the Session object lists the client's capabilities.",
                    "1530": "Unable to force unpublish. The client's token does not have the role set to moderator. Once the client has connected to the session, the capabilities property of the Session object lists the client's capabilities.",
                    "1535": "Force Unpublish on an invalid stream. Make sure that the stream has not left the session before you call the forceUnpublish() method.",
                    "1550": "Screen sharing is not supported (and you set the videoSource property of the options parameter of OT.initPublisher() to \"application\", \"screen\", or \"window\"). Before calling OT.initPublisher(), you can call OT.checkScreenSharingCapability() to check if screen sharing is supported.",
                    "1551": "A screen-sharing extension needs to be registered but it is not. This error can occur when you set the videoSource property of the options parameter of OT.initPublisher() to \"application\", \"screen\", or \"window\". Before calling OT.initPublisher(), you can call OT.checkScreenSharingCapability() to check if screen sharing requires an extension to be registered.",
                    "1552": "A screen-sharing extension is required, but it is not installed. This error can occur when you set the videoSource property of the options parameter of OT.initPublisher() to \"screen\". Before calling OT.initPublisher(), you can call OT.checkScreenSharingCapability() to check if screen sharing requires an extension to be installed.",
                    "1553": "WebRTC ICE workflow error while trying to establish communication between clients in the session. Try publishing again or reconnecting to the session.",
                    "1554": "WebRTC ICE workflow error while trying to establish communication between clients in the session. Try resubscribing to the stream or reconnecting to the session.",
                    "1600": "Internal error -- WebRTC subscriber error. Try resubscribing to the stream or reconnecting to the session.",
                    "1601": "Internal error -- WebRTC publisher error. Try republishing or reconnecting to the session.",
                    "1605": "Stream limit exceeded. The client tried to subscribe to a stream in a session that has exceeded the limit for simultaneous streams.",
                    "2000": "Internal Error. Try reconnecting to the OpenTok session and trying the action again.",
                    "2001": "Connect Failed. Unexpected response from the OpenTok server. Try connecting again later.",
                    "2011": "Error calling OT.reportIssue(). Check the client's network connection.",
                    "2021": "Attempt to set the proxy URL after initiating a Session or Publisher object.",
                    "2022": "Attempt to set the proxy URL after it has already been set (by a previous call to OT.setProxyUrl()).",
                    "3000": "Maximum number of live speakers reached.",
                    "400": "One of the signal properties — data, type, or to — is invalid. Or the data cannot be parsed as JSON.",
                    "404": "The to connection does not exist.",
                    "413": "The type string exceeds the maximum length (128 bytes), or the data string exceeds the maximum size (8 kB).",
                    "500": "The client is not connected to the OpenTok session. Check that the client connects successfully before trying to signal. And check that the client has not disconnected before trying to signal."
                },
                "try_device_again": "Please try turning the video off and on again",
                "user": {
                    "1004": "Authentication error",
                    "1005": "Invalid session ID.",
                    "1006": "Unable to connect to the session.",
                    "1010": "The client is not connected to the session.",
                    "1011": "Invalid Parameter.",
                    "1013": "WebRTC PeerConnection error.",
                    "1026": "Server error.",
                    "1027": "Connection limit exceeded",
                    "1500": "Unable to publish the stream.",
                    "1520": "Unable to force disconnect.",
                    "1530": "Unable to force unpublish.",
                    "1535": "Force Unpublish on an invalid stream.",
                    "1550": "Screen sharing is not supported.",
                    "1551": "A screen-sharing extension needs to be registered but it is not.",
                    "1552": "A screen-sharing extension is required, but it is not installed.",
                    "1553": "WebRTC ICE workflow error.",
                    "1554": "WebRTC ICE workflow error.",
                    "1600": "Subscriber error. Try resubscribing to the stream or reconnecting to the session.",
                    "1601": "Publisher error. Try republishing or reconnecting to the session.",
                    "1605": "Stream limit exceeded.",
                    "2000": "Internal Error.",
                    "2001": "Connect Failed.",
                    "2011": "Internal Error.",
                    "3000": "You have reached the maximum number of live speakers. Please remove a speaker from live to add a new one",
                    "400": "One of the signal properties — data, type, or to — is invalid. Or the data cannot be parsed as JSON.",
                    "404": "The to connection does not exist.",
                    "413": "The type string exceeds the maximum length (128 bytes), or the data string exceeds the maximum size (8 kB).",
                    "500": "The client is not connected to the session"
                }
            },
            "keep_open": "Keep stream open",
            "loading_qna_failed": "Failed to load new questions",
            "modals": {
                "kicked_from_room_p1": "The host has invited you to leave the room.",
                "kicked_from_room_p2": "You can rejoin using the same stream link or close this tab.",
                "left_room_l1": "Thank you for joining us.",
                "left_room_l2": "Would you like to give us feedback on your experience? You can now close this tab.",
                "private_qna_send": "Send",
                "private_qna_text": "Since you are currently utilizing the Private Q&A setup, please ensure that sharing your comment with everyone is your intended action.",
                "private_qna_title": "Your comment will be visible to all attendees",
                "room_closed": "The stream has ended and the room has been closed by the host, thanks for joining us. Would you like to give us feedback on your experience? You can now close this tab."
            },
            "multicast_credentials_body": "This session has captions enabled. Please provide the needed credentials or disable captions before opening the room.",
            "multicast_credentials_title": "Error configuring captions",
            "new_question_added": "New question has been added to \"Discussion\"",
            "no_incoming_data_text": "Please check your connection. If the issue persists, refresh the page.",
            "no_incoming_data_title": "You have disconnected from the session",
            "not_yet": "Not yet",
            "poll_order_saved": "Poll list successfully saved",
            "question_hide_from_screen_failed": "Failed to hide question",
            "question_moved_to": "Question moved to",
            "question_push_to_screen_failed": "Failed to show question",
            "question_save_error": "Falied to save Q&A question",
            "save_error": "Failed to save settings",
            "save_success": "Settings saved",
            "stop_preview_now": "Stop now",
            "stop_preview_title": "Stop preview",
            "stop_title": "End stream",
            "stop_title_3rdparty": "Reset stream",
            "titles": {
                "goodbye": "Goodbye!"
            }
        },
        "onboarding": {
            "form": {
                "mandatory_name": "Name is mandatory"
            },
            "no_device_error": "No device found",
            "no_mic": "No microphone found",
            "no_mic_modal": {
                "p1": "We couldn't find your microphone. Check that your microphone is correctly plugged in and is not being used by another app. Please also ensure that you have given permission for your browser to use your microphone.",
                "p2": "For more help go to the {0}.",
                "p2_link_label": "Knowledge Base",
                "title": "No microphone found"
            },
            "no_webcam": "No webcam found",
            "no_webcam_modal": {
                "p1": "We couldn't find your webcam. Check that your webcam is correctly plugged in and is not being used by another app. Please also ensure that you have given permission for your browser to use your webcam.",
                "p2": "For more help go to the {0}.",
                "p2_link_label": "Knowledge Base",
                "title": "No webcam found"
            },
            "select_mic": "Select microphone",
            "select_webcam": "Select camera"
        },
        "params": "Parameters",
        "polling": {
            "add_option": "Add an option",
            "allow_multi_answers": "Allow multiple answers",
            "allow_multi_answers_num": "Allow multiple answers | Allow multiple answers | Allow multiple answers: {count}",
            "allow_multi_words": "Allow multiple words",
            "answer_limit": "Answer limit",
            "answer_limit_hint": "Max. {0} answers",
            "back_to_list": "Back to list",
            "cannot_show_results_reason": "Cannot show results because there is a poll with voting open",
            "character_limit": "Character limit",
            "character_limit_hint": "Max. {0} characters",
            "character_limit_num": "Character limit: {0}",
            "close_poll": "Hide results",
            "close_voting": "Close poll",
            "closing_failed": "Closing of the poll failed",
            "countdown": {
                "duration_invalid_hybrid": "The duration must be minimum 60 seconds",
                "duration_invalid_inperson": "The duration must be minimum 1 second",
                "duration_too_big": "The section to skip needs to be shorter than the duration of the replay ({0})",
                "duration_too_small": "The duration must be greater than {0}",
                "follow_action": "When timer runs out",
                "follow_action_close": "Close poll - keep results visible",
                "follow_action_close_donot_show_results": "Close poll - don't show results",
                "follow_action_close_donot_show_results_live": "Close poll - hide results",
                "follow_action_close_hide_results": "Close poll - hide results",
                "follow_action_close_show_results": "Close poll - show results",
                "follow_action_close_show_results_live": "Close poll - keep results visible",
                "follow_action_close_show_results_live_correct": "Close poll - show correct answer(s)",
                "follow_action_do_nothing": "Don't close poll automatically",
                "hours_field": "hr",
                "hours_invalid": "The hours must be a number between 0 and 99",
                "minutes_field": "min",
                "minutes_invalid": "The minutes must be a number between 0 and 99",
                "seconds_field": "sec",
                "seconds_invalid": "The seconds must be a number between 0 and 59",
                "timer": "Timer",
                "timer_action_label": "When timer runs out {0}",
                "timer_add_15": "+ 15 sec",
                "timer_add_30": "+ 30 sec"
            },
            "created": "New poll created",
            "creation_failed": "Creating the poll failed",
            "delete_analytics_lost_hint": "Data will not be stored in the analytics report.",
            "delete_failed": "Failed to delete the poll",
            "delete_poll": "Delete this poll",
            "delete_prompt": "Are you sure you want to delete this poll?",
            "delete_success": "Poll deleted",
            "display_colors": "Display colors",
            "duplicate": "Duplicate",
            "duplicate_failed": "Failed to duplicate poll",
            "duplicate_success": "Poll successfully duplicated",
            "duplicates_error": "Options can't have the same value!",
            "edit_colors": "Edit colors",
            "edit_list": "Edit list",
            "edit_poll": "Edit poll",
            "enable": "Enable Polls",
            "execution_failed": "Poll execution failed",
            "general_settings": "General settings",
            "hide_poll_results_failed": "Hide poll results failed",
            "hide_poll_results_label": "Hide results",
            "infos": "INFOS",
            "keep_poll_results_label": "Keep results visible",
            "launch_poll": "Launch poll",
            "launch_warning_text": "Launching this poll will close the previous poll without showing results. Are you sure you want to launch this poll?",
            "launch_warning_title": "Another poll is already live",
            "live_display_targeting": {
                "all": "Live displays: All",
                "count": "Live displays selected",
                "info": "If all live displays are not selected, this poll will not automatically be linked to new live displays.",
                "label": "Live displays",
                "none": "Live displays: None",
                "placeholder": "Select live displays",
                "select_all": "Select all displays",
                "select_all_view": "Targets all displays"
            },
            "loading_polls_failed": "Polls for this live-stream couldn't be loaded",
            "mark_correct": "Mark option as correct",
            "max_allowed_answers": "Maximum allowed answers",
            "new_multiple_choice": "New multiple choice",
            "new_poll": "New poll",
            "new_word_cloud": "New word cloud",
            "no_polls": "Create your first poll and start engaging with your users",
            "no_polls_finished": "There was no poll in this live session",
            "no_polls_speaker": "There is no running poll. Moderators can start a poll.",
            "no_words_yet": "No words yet",
            "nonvoted": "Not Voted",
            "onscreen": "Live",
            "option": "Option",
            "options": "Options",
            "participation": "participation",
            "poll_closed": "Poll closed",
            "poll_closed_results_hidden": "Poll closed without showing results to participants",
            "poll_launched": "Poll launched",
            "poll_types": {
                "multiple_choice": "Multiple choice",
                "word_cloud": "Word cloud"
            },
            "polls_disabled": "The polls are disabled for this session",
            "pro_tip": "Pro tip",
            "pro_tip_hybrid": "from remote viewers",
            "pro_tip_message": "First results {0} will come {1}s after launching the poll. Animate the audience while waiting for results.",
            "question": "Question",
            "recipients": "Recipients",
            "recipients_count": "people saw it",
            "releasing_results_failed": "Releasing poll results failed",
            "remove_option": "Remove option",
            "results_coming": "First results {0} coming in {1}s",
            "results_coming_soon": "First results {0} coming in soon...",
            "results_hidden": "Results hidden from participants",
            "results_shown": "Results visible to participants",
            "save_failed": "Failed to save the poll",
            "save_poll": "Save poll",
            "saved": "Poll saved",
            "show_correct_answers": "Show correct answer(s)",
            "show_poll_results": "Show final results",
            "show_poll_results_failed": "Show poll results failed",
            "show_poll_results_label": "Show results",
            "show_poll_results_later": "Show results later",
            "show_poll_results_now": "Show results now",
            "show_results_live": "Show results as they come in",
            "show_results_live_tooltip": "After participants vote, they will see other participants' votes coming in real-time.",
            "submitted_words": "Submitted words",
            "targeting": {
                "applied": "APPLIED",
                "not_applied": "VISIBLE TO EVERYONE",
                "remove_text": "Are you sure you want to remove targeting to all the selected polls?",
                "remove_title": "Remove targeting",
                "select_all": "Select all",
                "selected_count": "Poll{0} selected",
                "targeting_applied": "Targeting applied",
                "targeting_applied_notification": "Targeting saved successfully",
                "targeting_failed_notification": "Targeting could not be saved. Please try again",
                "targeting_removed_notification": "Targeting removed successfully",
                "title": "Targeting"
            },
            "title": "Polls",
            "unique_voting_participants": "people voted",
            "unmark_correct": "Remove correct",
            "voted": "Voted",
            "voted_results_not_shown": "Voted, results not shown",
            "votes": "votes",
            "voting_closed": "Voting closed"
        },
        "preferences": {
            "messages": {
                "blocked_words_add_language_success": "The language \"{0}\" has been successfully added.",
                "blocked_words_add_success": "The word \"{1}\" has been successfully added.",
                "blocked_words_delete_language_confirm": "<span style=\"display:block;\">Are you sure you want to delete this language?</span><span>This action is irreversible and all the words within this language will be lost</span>",
                "blocked_words_delete_language_success": "The language \"{0}\" has been successfully deleted.",
                "blocked_words_delete_word_success": "The word \"{1}\" has been successfully deleted from language \"{0}\".",
                "blocked_words_restored_words_success": "The words have been successfully restored in language \"{0}\".",
                "more_than_10_percent_warning_subtitle": "Please be aware that the skipped part will not be visible to attendees once the video is published.",
                "more_than_10_percent_warning_title": "Are you sure you want to skip more than 10% of the video?",
                "settings_save_failure": "An error occurred while saving the settings, try again later or contact the support",
                "settings_save_success": "The settings have been successfully saved."
            },
            "word_cloud_blocked_words": {
                "add_language": "Add Language",
                "add_word": "Add Word",
                "delete_language": "Delete language",
                "restore": "Restore",
                "toggler_label": "Block the submission of words"
            }
        },
        "preview": "Preview",
        "qna": {
            "anonymous": "Anonymous",
            "answer_again": "Answer again",
            "answer_now": "Answer now",
            "answered": "Answered",
            "answering_now": "Answering now",
            "approve": "Approve",
            "approved": "Discussion",
            "archive": "Archive",
            "archived": "Archived",
            "disabled": "disabled",
            "done_answering": "Answered",
            "edit_question_tooltip": "Edit",
            "empty": "There are no questions coming from the audience",
            "enable": "Enable Q&A",
            "highlight_warning_text": "Answering this question will close the currently running poll without showing results. Are you sure you want to answer this question?",
            "highlight_warning_title": "A poll is currently live",
            "move_to_discussion": "Move to discussion",
            "new_question_prompt": "Write your comment",
            "new_questions_added": "New question(s)",
            "pending": "Incoming",
            "private_qna": "Private Q&A",
            "qna_disabled": "The Q&A is disabled for this session",
            "question_edit": "Edit question",
            "restore": "Restore",
            "send_new_question": "Send",
            "sort_by_newest": "Time",
            "sort_by_upvotes": "Upvotes",
            "title": "Q&A",
            "undo": "Undo"
        },
        "questions": "Q&A",
        "reactions": "Reactions",
        "reactions_disabled": "disabled",
        "reconnection": {
            "error": {
                "body": "Please check your Internet connection and refresh the page.",
                "title": "We are unable to connect you to Studio"
            },
            "reconnecting": {
                "body": "Your network seems to be down. Please wait while we try to reconnect.",
                "title": "Connectivity issues"
            },
            "temporary": {
                "body": "You might be experiencing some connectivity issues. Please wait a few seconds.",
                "title": "Temporarily disconnected"
            }
        },
        "screenshare": {
            "audio_disabled_modal": {
                "button": "Accept",
                "p1": "To be able to connect the audio you need to follow these steps:",
                "step1": "1 - Stop screensharing.",
                "step2": "2 - Remove the screenshare from the green room section (beneath the preview).",
                "step3": "3 - Share the content again but remember to enable the audio.",
                "title": "Audio is not enabled in the screenshare"
            },
            "exceeded_capacity": "Can't share because there are {0} screens being shared already",
            "permission_modal": {
                "p1": "Google Chrome might not have screen-recording permission on your computer. Go to {0} and enable it for this browser.",
                "p1_link_label": "System Preferences",
                "p2": "For more help go to the {0}.",
                "p2_link_label": "Knowledge Base",
                "title": "Can't share your screen"
            }
        },
        "settings": {
            "attending_in_person": "Attending in person",
            "attending_remotely": "Attending remotely",
            "background": {
                "hint": "File types: PNG, JPEG. Max file size 5MB. Recommended resolution 1920x1080px.",
                "label": "Background",
                "rtmps_explanation": "When slides are broadcasted live, this background will appear below slides and the video frame."
            },
            "captions_section": "Captions",
            "controls_title": "Video controls",
            "design_section": "Design",
            "event_name": "Name",
            "general_section": "General",
            "interactive_section": "Interactivity",
            "interactivity_subtitle": "Replay live session interactivity, with the ability to both rewatch and engage with claps during the VOD.",
            "live_captions": {
                "broadcast_placeholder_key": "xxxx-xxxx-xxxx-xxxx",
                "broadcast_placeholder_url": "rtmp://example.com/...",
                "broadcast_subtitle": "Studio will broadcast to this endpoint. Paste the details from your captioning proxy here.",
                "broadcast_title": "Broadcast URL",
                "caption_1": "CC1",
                "caption_2": "CC2",
                "caption_3": "CC3",
                "caption_4": "CC4",
                "captions_link_kb_ai_media": "Learn more how to get started with AI Media.",
                "captions_link_kb_wordly": "Learn more how to get started with Wordly.",
                "captions_toogle_in_person": "In-person attendees",
                "captions_toogle_virtual": "Virtual attendees",
                "contact_us": "Contact us",
                "contact_us_text": "Make your live stream more inclusive and accessible with live captions for up to 4 languages",
                "contact_us_title": "Reach new audiences with captions",
                "disabled_text": "Add captions to your livestream",
                "enable": "Enable captions",
                "key": "Key",
                "livestream_rtmp_placeholder": "Start the preview to generate the RTMP URL",
                "livestream_rtmps_placeholder": "Start the preview to generate the RTMPS URL",
                "multicast_key_match": "Wordly key and session id don't match",
                "multicast_key_placeholder": "xxxx-xxxx_xxxx",
                "multicast_session_id_placeholder": "Your session ID",
                "multicast_subtitle": "Enter your Wordly key and session id to connect the closed captions to the live session.",
                "multicast_title": "Wordly Settings",
                "placeholder_caption_1": "English",
                "placeholder_caption_2": "French",
                "placeholder_caption_3": "Spanish",
                "placeholder_caption_4": "German",
                "rtmps_subtitle": "Configure your live caption proxy to push captioned video to this media URL.",
                "rtmps_title": "Media URL",
                "session_id": "Session ID",
                "show_less_channels": "Show less",
                "show_more_channels": "Show all channels",
                "track_label_title": "Language caption channel map",
                "url": "URL"
            },
            "live_display": {
                "access_link": "Access link",
                "access_link_placeholder": "Save the display to generate the access link",
                "add_display": "Add another display",
                "background_image_hint": "The image will be stretched to adapt on different displays’ resolutions. File types: PNG, JPEG. Max file size 5MB. Recommended min. resolution width 1920px.",
                "color": "Fill color",
                "delete": "Delete",
                "design": "Design",
                "disabled_display_text": "Get everyone on-site engaged with polls and Q&A by displaying them on a TV or a projector at the event venue.",
                "disabled_display_title": "Bridge the gap between remote and in-person attendees",
                "display_placement": "Live display placement",
                "display_settings": "Display settings",
                "display_settings_on_site": "On-site",
                "display_settings_virtual": "Virtual hub",
                "enable": "Add a live display",
                "font_family": "Font family",
                "image": "Image",
                "intro_text": "Show the live stream, polls and Q&As on your displays at the event venue.",
                "name": "Name",
                "polls": "Polls",
                "polls_list": "Connected polls will be displayed here.",
                "remove": "Remove",
                "rename": "Rename",
                "settings": "Settings",
                "show_claps": "Show claps",
                "show_interactivity": "Interactivity",
                "show_interactivity_hint": "- running polls & questions being answered now",
                "show_live_stream": "Show live stream",
                "show_live_stream_hint": "Running polls and questions being answered now will cover the live stream.",
                "show_slides": "Slides",
                "virtual_location_hint": "When choosing this option, both interactivity and slides, if chosen, will adhere to the standard delay associated with the stream."
            },
            "live_display_section": "Live displays",
            "moderator_permalink": {
                "hint": "Moderators can manage Q&A and polls.",
                "label": "Moderator permalink"
            },
            "permalinks": "Permalinks",
            "recommended_settings": {
                "audio_settings": "Audio settings",
                "bitrate": "Bitrate",
                "codec": "Codec",
                "copy": "Copy",
                "frame_rate": "Frame rate",
                "keyframe": "Keyframe interval (or GOP - group of pictures)",
                "keyframe_value": "2 seconds or 60 frames",
                "preset": "Preset",
                "preset_value": "Can be set from \"medium\" to \"veryfast\"",
                "profile": "Profile",
                "profile_value": "Main/High",
                "rate_control": "Rate control",
                "rate_control_value": "CBR (Constant bitrate)",
                "sample_rate": "Sample rate",
                "stereo": "stereo",
                "title": "Recommended encoding settings",
                "to": "to",
                "video_settings": "Video settings",
                "warning": "Please make sure that you follow the below recommended settings in order to avoid serious issues with the live stream."
            },
            "registration": {
                "copy_link": "Copy link",
                "preview_page": "Open page"
            },
            "registration_section": "Registration page",
            "start_time": "Starts at",
            "timezone": "Time zone",
            "timezone_placeholder": "Time zone",
            "video_section": {
                "bandwidth_x1080": "bandwidth 3991 kbps",
                "bandwidth_x360": "bandwidth 1021 kbps",
                "bandwidth_x432": "bandwidth 1461 kbps",
                "bandwidth_x540": "bandwidth 1791 kbps",
                "bandwidth_x720": "bandwidth 2341 kbps",
                "blurred_background": "Blurred background",
                "closed_captions_title": "Closed captions",
                "enterprise_resolution_form_value": "Limit attendees' video resolution",
                "enterprise_resolution_subtitle": "If attendees are in an enterprise environment with limited bandwidth, you can limit the participants resolution to avoid potential issues related to bandwidth constraints.",
                "enterprise_resolution_title": "Bitrate restriction",
                "section_title": "Video",
                "start_position": "Skip intro until",
                "start_position_subtitle": "Remove the start of the video from the published recording.",
                "support_enterprise_env": "Support enterprise environments",
                "video_controls_fullscreen": "Full screen",
                "virtual_backgrounds": "Virtual and blurry background",
                "virtual_backgrounds_description": "Moderators can upload backgrounds in the left sidebar, making them available to moderators and speakers."
            },
            "vod_title": "Video on Demand (VOD) settings",
            "webinar_banner": {
                "hint": "File types: PNG, JPEG. Max file size is 10MB. The recommended resolution is 2880x692px.",
                "label": "Banner"
            },
            "webinar_brand_color": {
                "hint": "Brand color is applied on the registration page, automated emails and the webinar.",
                "label": "Brand color"
            },
            "webinar_description": {
                "label": "Description"
            },
            "webinar_icon": {
                "hint": "This icon will be used in registration, emails and on the webinar page. Accepted file types: PNG and JPEG. Max file size: 2MB. Recommended resolution 512x512px.",
                "label": "Webinar icon"
            },
            "webinar_registration_fields": {
                "label": "Fields"
            }
        },
        "setup": {
            "captions_button": "Setup live captions",
            "delay_disclaimer_bottom": "Your presence on-site is crucial for managing interactive elements effectively.",
            "delay_disclaimer_top": "Please note that there is a delay of approximately 30 seconds between the video and real-time event.",
            "intro": "You need to start the preview to generate RTMPS credentials, if you don't start broadcasting within {0} the stream will be reset and you will need to generate new stream credentials. If no video input is received within 1 hour of starting, the preview will close automatically.",
            "know_more_link": "More information about streaming with RTMPS",
            "title": "Stream settings"
        },
        "slides": {
            "cancel": "Cancel",
            "change_slide_failed_text": "Please try again after a few seconds.",
            "change_slide_failed_title": "Could not change slide",
            "config_slideshow": "Configure",
            "control": "Control",
            "control_all": "All speakers",
            "control_custom": "Custom",
            "control_none": "No speakers",
            "control_number": "No speakers | 1 speaker | {n} speakers",
            "control_settings_error": "Error updating slide controls settings",
            "control_settings_saved": "Slide controls settings successfully saved",
            "delete_button": "Delete",
            "edit": "Edit list",
            "live": "LIVE",
            "modal_button_confirm_control_all": "Give control to all speakers",
            "modal_button_confirm_control_custom": "Override default setting",
            "modal_button_confirm_control_none": "Deny all speakers slide control",
            "modal_subtitle": "Please be aware that currently we are not supporting slides animations.",
            "modal_text_control_all": "All speakers will be able to control all of the slide decks by default. If you would like to customise control per deck, you can manage this directly in each deck.",
            "modal_text_control_custom": "You have chosen to give specific people access to control the slides. This will override the default slide control setting.",
            "modal_text_control_none": "No speakers will be able to control any of the slide decks, by default. If you would like to customise control per deck, you can manage this directly in each deck.",
            "modal_title": "Upload slides",
            "modal_title_control_all": "Are you sure want to update the default slide control setting?",
            "modal_title_control_custom": "Are you sure you want to override slide control?",
            "modal_title_control_none": "Are you sure want to update the default slide control setting?",
            "no_slides": "No slides",
            "no_slides_live": "There are no slides live",
            "present_button": "Present now",
            "present_button_disabled_notification": "Make sure that there are no more than {maxSpeakers} speakers on stage (including the host) to be able to present. Then try again.",
            "present_button_disabled_tooltip": "More than {maxSpeakers} speakers are on stage",
            "present_slideshow_failed_text": "Please try again after a few seconds.",
            "present_slideshow_failed_title": "Could not start presenting",
            "presenting_now": "Presenting now",
            "preview_slideshow": "Preview",
            "push_live": "PUSH LIVE",
            "remove_from_stage": "Remove from stage",
            "retrieval_failed": "Failed to retrieve the list of slideshows",
            "save_list": "Save",
            "select_slides": "Upload slides",
            "slide_count_plural": "slides",
            "slide_count_singular": "slide",
            "slide_number_of_total": "{num} of {total}",
            "slides_controls": "Slide controls",
            "slides_list_changed": "Slides list successfully saved",
            "slideshows_update_failed_text": "Please try uploading the slideshows again",
            "slideshows_update_failed_title": "An error occurred",
            "speakers_control_confirm": "Save",
            "speakers_control_table_header": "Full name",
            "speakers_control_text": "Decide which speaker can control the slides",
            "speakers_control_title": "Slides control for speakers",
            "speakers_control_warning": "If a speaker exits the room / loses connectivity, you will have to review the slide control settings, to ensure they still have control access.",
            "speakers_with_control_all": "All speakers",
            "speakers_with_control_edit": "Edit speaker control",
            "speakers_with_control_none": "None",
            "speakers_with_control_title": "Speakers that have control over this deck",
            "start_presenting_modal_confirm": "Present now",
            "start_presenting_modal_latest_slide_option": "Display the latest slide that was being presented",
            "start_presenting_modal_title": "Present now \"{0}\"?",
            "stop_presenting_failed_text": "Please try again after a few seconds.",
            "stop_presenting_failed_title": "Could not stop presenting",
            "stop_presenting_modal_confirm": "Remove from stage",
            "stop_presenting_modal_text": "Are you sure you want to remove these slides from the stage?",
            "stop_presenting_modal_title": "Remove from stage \"{0}\"?",
            "title": "Slides"
        },
        "speakers": {
            "invite_p1": "Share the link below to invite speakers.",
            "invite_p1_aot": "Create studio access links for the speakers ahead of the session. You may also want to share our {0} with them.",
            "invite_p1_link_label_aot": "instructions for joining",
            "invite_p2": "We recommend you also share {0} with them.",
            "invite_p2_link_label": "speaker instructions for joining",
            "invite_speakers": "Invite speakers",
            "invite_speakers_aot": "Invite speakers ahead of time",
            "name": "Name",
            "rename": "Rename",
            "speaker_is_live": "This speaker currently live!",
            "speaker_permissions_hint": "Speakers can view approved questions and see polling results.",
            "title": "Speakers",
            "waiting_room_msg": "The host hasn’t opened this room yet. Once it has been opened you will be able to join.",
            "waiting_room_title": "It’s almost time"
        },
        "state": "State",
        "state_message": {
            "error": "An unexpected error occurred, please contact support",
            "generating_replay": "Preparing video on demand, this might take a while",
            "non_host_waiting": "This stream preview is not available yet. Please wait for the host to start it.",
            "past": "This stream has ended",
            "reserve": "You will have {0} to start broadcasting, after that the stream will be reset",
            "reserving": "Generating credentials, this might take a minute",
            "stopping": "Stopping your live stream, this might take a while",
            "waiting_for_input_stream": "Waiting for input stream..."
        },
        "states": {
            "publisher_bad_connection": "There's an issue with your connection or computer performance, click for more information",
            "subscriber_bad_connection": "There's an issue with this speaker's connection or computer performance, click for more information"
        },
        "streamKey": "Stream Key",
        "thirdparty": {
            "obs": "Use with OBS",
            "obs_link": "http://sl.spotme.com/stream-with-obs",
            "watch_stream": "Watch the stream on third party service",
            "webex": "Use with WebEx",
            "webex_link": "http://sl.spotme.com/stream-with-webex",
            "zoom": "Use with Zoom",
            "zoom_link": "http://sl.spotme.com/stream-with-zoom"
        },
        "thumbnails": {
            "actions": "More actions",
            "camera_live_toggler_disabled": "Stop {elementOnScreen} sharing to add more than {maxSpeakers} speakers to the stream",
            "camera_live_toggler_disabled_max_speakers": "It is not possible to add more than {maxSpeakers} speakers to the stream",
            "disable_audio": "Turn off audio",
            "disable_video": "Turn off video",
            "enable_audio": "Turn on audio",
            "enable_audio_request": "Ask to turn audio on",
            "enable_video": "Turn on video",
            "enable_video_request": "Ask to turn video on",
            "live": "Live",
            "mute": "Mute",
            "remove": "Stop sharing",
            "screen_share": "Scr. Share",
            "screenshare_live_toggler_disabled": "Make sure that there are no more than {0} speakers on stage to be able to screen share",
            "unmute": "Unmute",
            "unmute_request": "Ask to unmute"
        },
        "title": "Studio",
        "tooltips": {
            "analytics": "Download analytics reports",
            "back": "Back to live sessions list",
            "preview": "Preview the live session",
            "preview_webinar": "Preview the webinar",
            "settings": "Edit live session settings",
            "webinar_settings": "Edit webinar settings"
        },
        "url": "Live Stream Url",
        "video_library": {
            "about_to_end": "Video is about to be removed",
            "about_to_start": "Video is about to start playing",
            "cancel": "Cancel",
            "close": "Close",
            "delete_video": "Delete video",
            "delete_video_first_text": "Are you sure you want to delete this video?",
            "delete_video_title": "Delete video",
            "edit": "Edit list",
            "error": "An unexpected error occurred.",
            "error_not_found": "Video was not found.",
            "error_previewing": "An error occurred while trying to preview the video. Please try again.",
            "error_sharing": "An error occurred while trying to broadcast video. Please try again.",
            "error_upload_again": "Please try uploading it to the video library again.",
            "get_ready": "Video will end in",
            "hint_subtitle": "For instance, a high-resolution 4&nbsp;GiB, 15-minute video can take up to 20 minutes to process. To ensure your video is ready on time, please upload it well in advance.",
            "hint_title": "Video processing time varies based on file size, resolution, and duration.",
            "no_restore": "Don't restore",
            "no_videos": "No video files",
            "play_video": "Play video",
            "preview": "Preview",
            "preview_video": "Preview video",
            "remove": "Remove",
            "remove_from_stage": "Remove from stage",
            "remove_from_stage_first_text": "The video will be removed from stage.",
            "remove_from_stage_restore_stage": "Restore the stage to what it was before playing the video",
            "replace": "Replace",
            "replace_video_text": "Are you sure you would like to replace the video that is playing now?",
            "replace_video_title": "Replace video",
            "restore": "Restore",
            "restore_stage_text": "Do you want to restore the stage to what it was before playing the video?",
            "restore_stage_title": "Something went wrong",
            "save_list": "Save list",
            "select_video": "Add videos",
            "send_email_notification": "Notify me by email when processing is complete",
            "share_video_first_text": "When you broadcast the video, any screen shared content or onscreen speakers will be hidden. The audience will only see the video.",
            "share_video_second_text": "Are you sure you want to broadcast the video?",
            "share_video_title": "Play video now",
            "title": "Videos",
            "use_video": "Play now",
            "video_list": "Video list",
            "video_playing": "Playing now",
            "video_ready": "Video ready for stage",
            "videos_list_changed": "Videos list successfully changed",
            "volume_control_tooltip": "Volume control has no effect on the video as seen by the viewers."
        },
        "virtual_background": {
            "blur": "Blur",
            "delete_button": "Delete",
            "delete_modal_text": "Are you sure you want to delete this background?",
            "delete_modal_title": "Delete {0}",
            "no_backgrounds": "No camera backgrounds yet",
            "preview": "Preview",
            "preview_failed": "Failed to display background video preview. Please try again.",
            "recommended_resolution": "Recommended resolution: 1280x720px",
            "title": "Background",
            "title_camera": "Camera background",
            "title_long": "Virtual and blurry background",
            "title_short": "BG",
            "unsupported": "Blurred background is not supported on your system",
            "upload_backgrounds": "Upload backgrounds",
            "warning": "Using a virtual background may use addional CPU resources. Please make sure that your computer meets the hardware requirements <a href='{0}' target='_blank'>listed here</a>."
        }
    }
}
