/** @module Time */

// Utils
import moment from 'moment-timezone';
import { isNumber } from 'lodash';

import { getFirstEligibleLocale } from './locales';

// Constants
import { DEFAULT_DATETIME_FORMAT } from 'libs/utils/constants';


moment.locale('en');

/**
 * @const {string} DEFAULT_TIMEZONE_IDENTIFIER 'Europe/Zurich'
 */
export const DEFAULT_TIMEZONE_IDENTIFIER = 'Europe/Zurich';

/**
 * Get name of available timezones
 *
 * @return {String[]} an array of strings containing all available timezones.
 */
export function getAllZones() {
    const allowedWithoutSlash = ['EST', 'UTC'];
    return (moment.tz.names() || [])
        // filter problematic timezones
        .filter(zone => !zone.startsWith('Etc/') &&
            !zone.startsWith('US/') &&
            (zone.includes('/') || allowedWithoutSlash.includes(zone))
        );
}

/**
 * Gets the localized date format based on the browser configuration
 *
 * @param {String} [locale] the desired locale. If not provided the system will try to guess.
 * @param {import("moment").LongDateFormatKey} [format='L'] the generic desired format
 *
 * @return {String} the specified date format
 */
export function getBrowserDateFormat(locale, format = 'L') {
    if (!locale) {
        locale = getFirstEligibleLocale(moment.locales());
    }

    moment.locale(locale);
    const localeData = moment.localeData();

    return localeData.longDateFormat(format);
}

/**
 * Checks if the provided value is a valid date.
 *
 * @param {Date|string|number} date - The value to check. Can be a Date object, a string, or a timestamp.
 *
 * @returns {boolean} Returns true if the value is a valid date, otherwise false.
 */
export function isValidDate(date) {
    if (typeof date === 'string' || typeof date === 'number') {
        date = new Date(date);
    }
    return date instanceof Date && !isNaN(date.getTime());
}

/**
 * Returns the start of the day for a given date in the specified timezone.
 *
 * @param {Date|string|number} date - The date to calculate the start of the day for. Can be a Date object, a string, or a timestamp.
 * @param {string} [timezone] - The timezone to use for the calculation.
 *
 * NOTE: this method returns the UTC date of the start of the day in the specified timezone.
 *
 * @returns {Date} A Date object representing the start of the day in the specified timezone.
 */
export function startOfDay(date, timezone) {
    if (typeof date === 'string' || typeof date === 'number') {
        date = new Date(date);
    }
    let start = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0, 0);
    if (timezone) {
        start = moment.tz(start, timezone).toDate();
    }
    if (!isValidDate(start)) {
        throw new Error(`Invalid date: "${date}"`);
    }
    return start;
}

/**
 * Returns the end of the day for a given date in the specified timezone.
 *
 * @param {Date|string|number} date - The date to calculate the end of the day for. Can be a Date object, a timestamp, or a string parsable by Moment.js.
 * @param {string} [timezone] - The timezone to use for the calculation.
 *
 * NOTE: this method returns the UTC date of the end of the day in the specified timezone.
 *
 * @returns {Date} A Date object representing the end of the day in the specified timezone.
 */
export function endOfDay(date, timezone) {
    if (typeof date === 'string' || typeof date === 'number') {
        date = new Date(date);
    }
    let end = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59, 999);
    if (timezone) {
        end = moment.tz(end, timezone).toDate();
    }
    if (!isValidDate(end)) {
        throw new Error(`Invalid date: "${date}"`);
    }
    return end;
}

/**
 * Returns the date of today on the beginning of the day.
 * @param {string} [timezone] - The timezone to use for the calculation.
 *
 * @return {Date} the beginning of today
 */
export function startOfToday(timezone) {
    return startOfDay(new Date(), timezone);
}

/**
 * Returns the date of today on the end of the day.
 * @param {string} [timezone] - The timezone to use for the calculation.
 *
 * @return {Date} the end of today
 */
export function endOfToday(timezone) {
    return endOfDay(new Date(), timezone);
}

/**
 * Returns start of the day timestamp from given date and timezone
 *
 * @param {Date} date
 * @param {String} tz
 * @return {Number} unix timestamp
 */
export function startOfDayFromDateAndTz(date, tz) {
    return moment(date).tz(tz).startOf('day').unix();
}

/**
 * Returns a guess of the user' browser timezone
 *
 * @return {String} the guessed user's timezone
 */
export function getBrowserTimezone() {
    return moment.tz.guess();
}

/**
 * Given a date and a format this method returns a string representing
 * the date in the specified format.
 *
 * @param {Date|import('moment-timezone').Moment} date the date to format
 * @param {String} [format=DEFAULT_DATETIME_FORMAT] the desired date format
 *
 * @returns {String} the formatted date string
 */
export function dateToFormat(date, format = DEFAULT_DATETIME_FORMAT) {
    return moment(date).format(format);
}

/**
 * Given a unix timestamp and a format this method returns a string representing
 * the date in the specified format.
 *
 * @param {Number} timestamp the timestamp to format
 * @param {String} [format=DEFAULT_DATETIME_FORMAT] the desired date format
 * @param {String} [timezone] an optional timezone, if not provided the browser timezone will be used
 *
 * @returns {String} the formatted date string
 */
export function tsToFormat(timestamp, format = DEFAULT_DATETIME_FORMAT, timezone = getBrowserTimezone()) {
    try {
        if (!isNumber(timestamp) && Number.isInteger(Number(timestamp))) {
            timestamp = Number(timestamp);
        }

        if (!Number.isFinite(timestamp)) {
            return timestamp;
        }

        if (timestamp > 9999999999) {
            // Milliseconds instead of seconds
            timestamp = Math.floor(timestamp / 1000);
        }

        ensureZoneIdentifierIsValid(timezone);
    } catch (error) {
        console.warn('[utils/time] %s', error.message);
        timezone = getBrowserTimezone();
    }

    return moment
        .unix(timestamp)
        .tz(timezone)
        .format(format);
}

/**
 * Given a date this method returns a string representing
 * the date in the long format with time.
 *
 * @param {Date} date the date to format
 *
 * @returns {String} the formatted date string
 */
export function toLongDateTimeFormat(date) {
    return dateToFormat(date, 'LLL');
}

/**
 * Given a date this method returns a string representing
 * the date in the long format.
 *
 * @param {Date} date the date to format
 *
 * @returns {String} the formatted date string
 */
export function toLongDateFormat(date) {
    return dateToFormat(date, 'LL');
}

/**
 * Given a timestamp this method returns a string representing
 * the date in the long format.
 *
 * @param {Number} timestamp the timestamp to format
 *
 * @returns {String} the formatted date string
 */
export function tsToLongDateFormat(timestamp) {
    return tsToFormat(timestamp, 'LL');
}

/**
 * Given a timestamp this method returns a string representing
 * the date and time in the long format.
 *
 * @param {Number} timestamp the timestamp to format
 * @param {string} [timezone] an optional timezone
 *
 * @returns {String} the formatted date string
 */
export function tsToLongDateTimeFormat(timestamp, timezone) {
    return tsToFormat(timestamp, 'LLL', timezone);
}

/**
 * Given a unix timestamp this method returns a string representing
 * the date in short european format (DD/MM/YYYY)
 *
 * @param {Number} timestamp the timestamp to format
 *
 * @returns {String} the formatted date string
 */
export function tsToShortDateFormat(timestamp) {
    return moment.unix(timestamp).format('DD/MM/YYYY');
}

/**
 * Transforms timestamp to time from now i.e. "a month ago"
 *
 * @param {Number} ts timestamp to convert
 * @return {String} time from now
 */
export function timeFromNow(ts) {
    if (!ts) {
        return '';
    }

    if (ts > 9999999999) {
        // Milliseconds instead of seconds
        ts = Math.floor(ts / 1000);
    }

    return moment.unix(ts).fromNow();
}

/**
 * Given a number of days, this method returns a date distant that amount of
 * days from now.
 *
 * @param {Number} days number of days from now
 *
 * @returns {Date} the resulting date
 */
export function daysFromNow(days) {
    return moment().add(days, 'days').toDate();
}

/**
 * Creates a new date which is equivalent to date + timeToAdd
 *
 * @param {String|Date|import('moment-timezone').Moment} date the date to start from
 * @param {Number} timeToAdd number of seconds/minutes/days/months/years to add
 * @param {'seconds'|'minutes'|'days'|'months'|'years'} unit unit of `timeToAdd`
 *
 * @returns {Date} the resulting date
 */
export function addToDate(date, timeToAdd, unit) {
    return moment(date).add(timeToAdd, unit).toDate();
}

/**
 * Creates a new date which is equivalent to date - timeToSubtract
 *
 * @param {String|Date|import('moment-timezone').Moment} date the date to start from
 * @param {Number} timeToSubtract number of seconds/minutes/days/months/years to subtract
 * @param {'seconds'|'minutes'|'days'|'months'|'years'} unit unit of `timeToSubtract`
 *
 * @returns {Date} the resulting date
 */
export function subtractFromDate(date, timeToSubtract, unit) {
    return moment(date).subtract(timeToSubtract, unit).toDate();
}

/**
 * Given a total number of seconds, this method returns a formatted date string
 * in the format HH:mm:ss, allowing times bigger than 24h.
 *
 * @param {Number} sec number of seconds to convert
 * @param {Boolean} [shortMode] if the format should be in short mode ie 'mm:ss'
 * @param {String} [empty] string to display if the duration is equal 0
 * @param {Boolean} [showMs] flag to show milliseconds
 *
 * @returns {String} the formatted date string
 */
export function formatSeconds(sec, shortMode = false, empty = '', showMs = false) {
    if (empty && empty.length && sec === 0) {
        return empty;
    }

    const hours = (Math.floor(sec / 3600)).toString();
    const minutes = (Math.floor(sec / 60) % 60).toString();
    const seconds = (Math.floor(sec % 60)).toString();

    let result;

    if (sec < 3600 && shortMode) {
        result = `${minutes.padStart(2, '0')}:${seconds.padStart(2, '0')}`;
    } else {
        result = `${hours.padStart(2, '0')}:${minutes.padStart(2, '0')}:${seconds.padStart(2, '0')}`;
    }

    if (showMs) {
        const ms = sec.toString().split('.')[1] || '000';
        result = `${result}.${ms.substring(0, 3).padEnd(3, '0')}`;
    }

    return result;
}

/**
 * Given a date string of the form "mm:ss" or "hh:mm:ss", this method returns the number of seconds it represents.
 * @param {String} timeString
 * @return {number}
 */
export function toSeconds(timeString) {
    const timeComponents = timeString.split(':').map(Number);
    if (timeComponents.length === 2) {
        const [minutes, seconds] = timeComponents;
        return minutes * 60 + seconds;
    } else if (timeComponents.length === 3) {
        const [hours, minutes, seconds] = timeComponents;
        return hours * 3600 + minutes * 60 + seconds;
    }
}

/**
 *
 * @param {String} tzIdentifier
 */
export function ensureZoneIdentifierIsValid(tzIdentifier) {
    if (!moment.tz.names().includes(tzIdentifier)) {
        throw Error(`A timezone identifier must be provided; ${tzIdentifier} is not a valid timezone.`);
    }
}

/**
 * Return the representation of provide unix timestamp in targeted timezone
 * @param {Number} timestamp (unix)
 * @param {String} [tzIdentifier] optional
 * @param {String} [formatStr] optional
 * @return {string|any}
 */
export function convertTimestampToHumanForm(
    timestamp,
    tzIdentifier,
    formatStr = DEFAULT_DATETIME_FORMAT
) {
    try {
        return tsToFormat(timestamp, formatStr, tzIdentifier);
    } catch (e) {
        return `${timestamp}`;
    }
}

/**
 * From with given format to a long version of the date
 * @param {String} dateStr
 * @param {String} [format] optional
 * @return {String}
 */
export function convertDateStringToFullLocaleString(
    dateStr,
    format = DEFAULT_DATETIME_FORMAT
) {
    return moment(dateStr, format, true).local().format('Do MMMM, YYYY');
}

/**
 * In object format of timestamp fields as specificed in the metadata
 * @param {Object} obj
 * @param {Object} metadataFields
 * @param {String} tzIdentifier
 */
export function convertTimestampsToHumanFormInObject(
    obj,
    metadataFields,
    tzIdentifier
) {
    return (
        metadataFields
            .filter(({ kind }) => kind === 'timestamp')
            .forEach(({ field }) =>
                obj[field] = convertTimestampToHumanForm(obj[field], tzIdentifier)
            )
    );
}

/**
 * Try to convert a date as string in given timezone to unix timestamp
 * @param {String} dateStr
 * @param {String} tzIdentifier optional
 * @param {String} format optional
 * @return {Number}
 */
export function convertDateStringToUTCTimestamp(
    dateStr,
    tzIdentifier,
    format = DEFAULT_DATETIME_FORMAT
) {
    ensureZoneIdentifierIsValid(tzIdentifier);
    // parse it with strict mode, otherwise 1/1/18 is considered as valid but the year is misplaced
    let date = moment.tz(dateStr, format, true, tzIdentifier);

    // fallback on new Date to support previous behaviour
    if (!date.isValid()) {
        dateStr = moment(new Date(dateStr)).format(DEFAULT_DATETIME_FORMAT);
        date = moment.tz(dateStr, format, tzIdentifier);
    }

    if (!date.isValid()) {
        console.log(`[utils/time] Parsing invalid date ${dateStr}`, {
            tzIdentifier, format
        });
        return null;
    }

    return parseInt(date.unix());
}

/**
 * Gets the current date time's moment instance
 *
 * @returns {import('moment-timezone').Moment} the current moment date object
 */
export function now() {
    return moment();
}

/**
 * Performs a difference between the two given times
 *
 * @param {String|Date|import('moment-timezone').Moment} timeA starting time
 * @param {String|Date|import('moment-timezone').Moment} timeB ending time
 * @param {'years'|'months'|'weeks'|'days'|'hours'|'minutes'|'seconds'|'milliseconds'} [measurement='milliseconds']
 * the unit of measurement in which to get the difference
 *
 * @returns {Number} the difference between the two instants in the measurement unit specified
 */
export function timeDiff(timeA, timeB, measurement = 'milliseconds') {
    if (measurement === 'milliseconds') {
        return moment(timeA).diff(moment(timeB));
    }
    return moment(timeA).diff(moment(timeB), measurement);
}

/**
 * Checks if the given initialDate comes before the given date.
 *
 * @param {String|Date|import('moment-timezone').Moment} initialDate the starting date
 * @param {String|Date|import('moment-timezone').Moment} date the date to check against
 * @param {String} [timezone='local'] the timezone to apply to the dates
 *
 * @returns {Boolean} true if `initialDate` comes before `date`
 */
export function dateIsBefore(initialDate, date, timezone = 'local') {
    return moment.tz(initialDate, timezone).isBefore(date);
}

/**
 * Checks if the given initialDate comes after the given date.
 *
 * @param {String|Date|import('moment-timezone').Moment} initialDate the starting date
 * @param {String|Date|import('moment-timezone').Moment} date the date to check against
 * @param {String} [timezone='local'] the timezone to apply to the dates
 *
 * @returns {Boolean} true if `initialDate` comes after `date`
 */
export function dateIsAfter(initialDate, date, timezone = 'local') {
    return moment.tz(initialDate, timezone).isAfter(date);
}

/**
 * Checks if the given `initialDate` is between `startDate` and `endDate`
 *
 * @param {String|Date|import('moment-timezone').Moment} initialDate the starting date
 * @param {String|Date|import('moment-timezone').Moment} startDate the start date to check against
 * @param {String|Date|import('moment-timezone').Moment} endDate the end date to check against
 * @param {object} options
 * @param {Boolean} [options.includeStart=false] if true, return true when `initialDate == startDate`
 * @param {Boolean} [options.includeEnd=false] if true, return true when `initialDate == endDate`
 *
 * @returns {Boolean} true if `initialDate` is between `startDate` and `endDate`
 */
export function dateIsBetween(initialDate, startDate, endDate, { includeStart = false, includeEnd = false }) {
    const inclusivity = (includeStart ? '[' : '(') + (includeEnd ? ']' : ')');
    return moment(initialDate).isBetween(startDate, endDate, undefined, inclusivity);
}

/**
 * Checks if two dates are equal.
 *
 * @param {String|Date|import('moment-timezone').Moment} dateA one date
 * @param {String|Date|import('moment-timezone').Moment} dateB the date to check against
 *
 * @returns {Boolean} true if `dateA` is the same as `dateB`
 */
export function dateIsEqual(dateA, dateB) {
    return moment(dateA).isSame(dateB);
}

/**
 * Find and mark timeslots that overlap with blockedSlots datetime / interval.
 *
 * @param {TimeSlot[]} timeslots
 * @param {TimeSlot[]} blockedSlots
 * @param {number} duration in seconds
 *
 * @returns {TimeSlot[]}
 */
export function markTimeslotsOverlapWithBlocked(timeslots, blockedSlots, duration) {
    return timeslots.map(slot => {
        const slotStart = moment(slot.start);
        const slotEnd = addToDate(slotStart, duration, 'seconds');

        slot.isRangeOverlap = false;

        for (const block of blockedSlots) {
            if (dateIsBefore(slotStart, block.start)) {
                break;
            }

            const blockStart = block.blockStart = block.blockStart ? block.blockStart : moment(block.start);
            const blockEnd = block.blockEnd = block.blockEnd ? block.blockEnd : moment(block.end);

            const isRangeOverlap = dateRangeOverlaps({ start: slotStart, end: slotEnd }, { start: blockStart, end: blockEnd });

            if (isRangeOverlap) {
                slot.isRangeOverlap = isRangeOverlap;

                break;
            }
        }

        return slot;
    });
}

/**
 * Checks if two dates overlap.
 *
 * @typedef {String|Date|import('moment-timezone').Moment} DateInput
 * @typedef { {start: DateInput, end: DateInput} } DateRange
 *
 * @param {DateRange} rangeA one date range
 * @param {DateRange} rangeB the date range to check against
 *
 * @returns {Boolean} true if `rangeA` overlaps `rangeB`
 */
export function dateRangeOverlaps(rangeA, rangeB) {
    const startA = moment(rangeA.start);
    const endA = moment(rangeA.end);

    const startB = moment(rangeB.start);
    const endB = moment(rangeB.end);

    return endA.isAfter(startB) && startA.isBefore(endB);
}

/**
 * Converts the given unix timestamp to a date.
 *
 * @param {Number} ts the unix timestamp to convert
 *
 * @returns {import('moment-timezone').Moment} the date instance
 */
export function unixToDate(ts) {
    return moment.unix(ts);
}

/**
 * Converts the given date into a unix timestamp (in seconds).
 *
 * @param {String|Date|import('moment-timezone').Moment} date the date instance
 *
 * @returns {Number} the unix timestamp in seconds
 */
export function dateToUnix(date) {
    return moment(date).unix();
}

/**
 * Given a timestamp in milliseconds, this method returns a formatted date string
 *
 * @param {Number} timestamp number of seconds from now
 * @param {String} timezone the timezone to apply to the timestamp
 * @param {String} [format='H:mm'] the desired time format
 *
 * @returns {String} the formatted date string
 */
export function fomatTimeWithTimezone(timestamp, timezone, format = 'H:mm') {
    return moment.tz(timestamp, 'UTC').tz(timezone).format(format);
}

/**
 * Transforms the given seconds in human-readable form
 * @example
 * ```
 * humanizeDuration(60); // -> 1 hour
 * humanizeDuration(60, 'it'); // -> 1 ora
 * ```
 * @param {number} seconds the duration in seconds to humanize
 * @param {string} [locale='en'] the user's locale
 *
 * @returns {string} the human-readable duration
 */
export function humanizeDuration(seconds, locale = 'en') {
    return moment.duration(seconds, 'seconds').locale(locale).humanize();
}

/**
 * Transforms the given seconds in human-readable form, made for processes remaining durations, which should last several hours at most
 */
export function humanizeTimeLeft(seconds) {
    if (!seconds || seconds < 60) {
        return '< 1 minute';
    }

    if (seconds < 3600) {
        return `${Math.ceil(seconds / 60)} minutes`;
    }

    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    return `${hours} hours ${minutes} minutes`;
}

/**
 * Convert a date, which might be a Momentjs date or a string, as a standard JS Date
 *
 * @param {String|Date|import('moment-timezone').Moment} date
 *
 * @returns {Date} the same date as a JS Date
 */
function toDate(date) {
    if (moment.isMoment(date)) {
        return date.toDate();
    }
    return new Date(date);
}

/**
 * Computes the number of days in the month of the provided date.
 * @param {Date} date a date
 * @returns {Number} number of days in this month
 */
function daysInMonth(date) {
    return new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth() + 1, 0)).getUTCDate();
}


/**
 * @typedef Duration
 * @property {Number} years
 * @property {Number} months
 * @property {Number} days
 * @property {Number} hours
 * @property {Number} minutes
 */

/**
 * Computes the diff between two dates.
 *
 * We have this function because Moment.js "humanize" function rounds in very strange ways.
 * In this function we don't simply divide the number of days by 30 or 31 to get the number
 * of months. This would give an approximate number of days. Instead, we trust in JS Dates
 * to give us the right number of months and adjust days and years around that.
 *
 * This also support leap years, and it is not impacted by day saving timezones as
 * it compares UTC dates.
 *
 * @param {String|Date|import('moment-timezone').Moment} date1
 * @param {String|Date|import('moment-timezone').Moment} date2
 *
 * @returns {Duration} the diff
 */
function accurateDiff(date1, date2) {
    // sort dates
    [date1, date2] = [toDate(date1), toDate(date2)].sort((d1, d2) => d1 < d2 ? -1 : 1);

    const date1Units = [date1.getUTCMinutes(), date1.getUTCHours(), date1.getUTCDate(), date1.getUTCMonth(), date1.getUTCFullYear()];
    const date2Units = [date2.getUTCMinutes(), date2.getUTCHours(), date2.getUTCDate(), date2.getUTCMonth(), date2.getUTCFullYear()];
    const unitMax = [60, 24, daysInMonth(date1), 12];
    const duration = [0, 0, 0, 0, 0];

    // compute the durations in minutes, hours, days and months
    for (const unitIndex of [0, 1, 2, 3]) {
        if (date1Units[unitIndex] <= date2Units[unitIndex]) {
            duration[unitIndex] += date2Units[unitIndex] - date1Units[unitIndex];
        } else {
            duration[unitIndex + 1] -= 1;
            duration[unitIndex] += unitMax[unitIndex] - date1Units[unitIndex] + date2Units[unitIndex];
        }
        if (duration[unitIndex] < 0) {
            duration[unitIndex + 1] -= 1;
            duration[unitIndex] += unitMax[unitIndex];
        }
    }

    duration[4] += Math.abs(date1.getUTCFullYear() - date2.getUTCFullYear());
    return { years: duration[4], months: duration[3], days: duration[2], hours: duration[1], minutes: duration[0] };
}

/**
 * Computes the diff between two dates and create a human-readable string representation
 *
 * @param {String|Date|import('moment-timezone').Moment} date1
 * @param {String|Date|import('moment-timezone').Moment} date2
 *
 * @returns {string} the human-readable duration
 */
export function humanizeDateDiff(date1, date2) {
    const diff = accurateDiff(date1, date2);
    const result = [];
    for (const unit of ['years', 'months', 'days', 'hours', 'minutes']) {
        if (diff[unit] !== 0) {
            let unitText = unit;
            if (diff[unit] <= 1) {
                unitText = unitText.substring(0, unitText.length - 1);
            }
            result.push(`${diff[unit]} ${unitText}`);
        }
    }
    return result.join(' ');
}

/**
 * Return current unix timestamp (s)
 * @return {Number}
 */
export function nowUnixTimestamp() {
    return Math.floor(Date.now() / 1000);
}

/**
 * Returns the list of months in between the given date and now. This list is formated to be in a multiselect
 * @param {Number} startDate
 * @returns {Object[]}
 */
export function getMonthListToNow(startDate) {
    const iterationDate = moment(startDate);
    const endDate = moment().endOf('month');
    const months = [];
    while (iterationDate < endDate) {
        const monthSelectObject = {
            label: iterationDate.format('MMMM YYYY'),
            id: iterationDate.unix(),
            start: iterationDate.unix(),
            end: iterationDate.endOf('month').unix()
        };
        months.push(monthSelectObject);
        // We navigate back to the start of the month before incrementing, because adding one month to a 31 might not end well!
        iterationDate.startOf('month').add(1, 'month');
    }
    return months;
}

/**
 * Transform the given date to have the targeted timezone current time as a date with the same local time.
 * @param {Date|Object|Number} date
 * @param {string} tz
 * @returns the new date
 */
export function transformTzTimeToLocal(date, tz) {
    if (!tz) {
        return date;
    }
    const momentDate = moment.tz(date, tz);
    momentDate.tz(moment.tz.guess(), true);
    return momentDate;
}

/**
 * Transform the given date to a timezone date with the same time.
 * @param {Date|Object|Number} date
 * @param {string} tz
 * @returns the new date
 */
export function transformLocalTimeToTz(date, tz) {
    if (!tz) {
        return date;
    }
    const momentDate = moment(date);
    momentDate.tz(tz, true);
    return momentDate;
}

/**
 * Returns a formatted time based on its components
 */
export function formatTime({ hours = 0, minutes = 0, seconds = 0, format = 'HH:mm:ss' } = {}) {
    return moment().hours(hours).minutes(minutes).seconds(seconds).format(format);
}

/**
 * Returns the start date of an event.
 *
 * @param {Object} event - The event object.
 *
 * @returns {Date} The start date of the event.
 */
export function getEventStartDate(event) {
    return new Date(event.startdate);
}

/**
 * Returns the end date of an event.
 *
 * @param {Object} event - The event object.
 *
 * @returns {Date} The end date of the event.
 */
export function getEventEndDate(event) {
    return new Date(event.enddate);
}

/**
 * Returns a date 2 years before
 *
 * @param {Date} date
 *
 * @returns {Date}
 */
export function twoYearsBefore(date) {
    return moment(date).subtract(2, 'years').add(1, 'day').toDate();
}

/**
 * Returns a date 2 years after
 *
 * @param {Date} date
 *
 * @returns {Date}
 */
export function twoYearsAfter(date) {
    return moment(date).add(2, 'years').subtract(1, 'day').toDate();
}

/**
 * Checks if two dates are the same day.
 *
 * @param {Date} dateA - The first date.
 * @param {Date} dateB - The second date.
 *
 * @returns {boolean} Returns true if the dates are the same day, otherwise false.
 */
export function sameDay(dateA, dateB) {
    return dateA.getFullYear() === dateB.getFullYear() &&
        dateA.getMonth() === dateB.getMonth() &&
        dateA.getDate() === dateB.getDate();
}

/**
 * Parses an ISO 8601 duration string and returns an object with the duration in hours, minutes, and seconds.
 *
 * @param {string} durationStr - The ISO 8601 duration string to parse (e.g., "PT2H30M15S").
 *
 * @returns {Object} An object containing the parsed duration.
 * @returns {number} return.hours - The number of hours in the duration.
 * @returns {number} return.minutes - The number of minutes in the duration.
 * @returns {number} return.seconds - The number of seconds in the duration.
 *
 * @see https://en.wikipedia.org/wiki/ISO_8601#Durations
 */
export function parseDuration(durationStr) {
    let [, years, months, weeks, days, hours, minutes, seconds] = /^P(?:(\d+)Y)?(?:(\d+)M)?(?:(\d+)W)?(?:(\d+)D)?(?:T(?:(\d+)H)?(?:(\d+)M)?(?:(\d+(?:\.\d+)?)S)?)?$/.exec(durationStr) || [0, 0, 0, 0];

    years = Number.parseInt(years) || 0;
    months = Number.parseInt(months) || 0;
    weeks = Number.parseInt(weeks) || 0;
    days = Number.parseInt(days) || 0;
    hours = Number.parseInt(hours) || 0;
    minutes = Number.parseInt(minutes) || 0;
    seconds = Number.parseFloat(seconds) || 0;

    return { years, months, weeks, days, hours, minutes, seconds };
}

/**
 * Adds a duration to a given date and returns the resulting timestamp.
 *
 * @param {string|Date} date - The initial date to which the duration will be added.
 * @param {string} isoDuration - The ISO 8601 duration string to add to the date.
 * @param {string} [timezone] - The timezone in which the date should be manipulated. Defaults to the browser's timezone.
 *
 * @returns {number} - The resulting timestamp after adding the duration, in seconds since the Unix epoch.
 */
export function datePlusDuration(date, isoDuration, timezone) {
    const duration = isoDuration ? parseDuration(isoDuration) : {};
    const now = moment(date).tz(timezone || getBrowserTimezone());

    for (const [unit, amount] of Object.entries(duration)) {
        now.add(amount, unit);
    }

    const ts = now.format('X');
    return Number.parseInt(ts);
}

/**
 * Calculates the current time plus a given ISO 8601 duration in a specified timezone.
 *
 * @param {string} isoDuration - The ISO 8601 duration string (e.g., "PT1H" for 1 hour).
 * @param {string} timezone - The timezone identifier (e.g., "America/New_York").
 * @returns {Date} The resulting date and time after adding the duration to the current time in the specified timezone.
 */
export function nowPlusDurationInUnixTs(isoDuration, timezone) {
    return datePlusDuration(new Date(), isoDuration, timezone);
}
