// Main class
import ServicePrototype from './service-prototype';

// Utils
import axios from 'axios';

import { getBackstageURL, interpolateUrl } from 'libs/utils/url';
import { requestErrorInterceptor } from 'libs/utils/net';

// XHR library global configuration
axios.interceptors.response.use(response => response, requestErrorInterceptor);
axios.defaults.withCredentials = true;

// Constants

import {
    CSRF_TOKEN_ENDPOINT,
    CSRF_TOKEN_ENDPOINT_ROUTER
} from 'libs/utils/constants';

/**
 * Base Service with basic functionality every http service should extends,
 * it is for mocking/testing and future Architecture
 */
export default class BaseService extends ServicePrototype {

    constructor() {
        super();

        /** @type {Services} */
        this._services;
    }

    /**
     * Gets a CSRF token
     *
     * @param {String} [baseUrl = ''] base URL
     * @return {Promise.<String>} the CSRF token
     */
    async getCsrfToken(baseUrl = '') {
        const { data: { token } } = await this.get(`${
            // remove trailing slash
            baseUrl.replace(/\/+$/, '')
        }${CSRF_TOKEN_ENDPOINT}`);

        return token;
    }

    /**
     * Gets a CSRF token on the specified node
     *
     * @param {String} nodeId the nodeId for the router
     *
     * @return {Promise.<String>} the CSRF token
     */
    async getCsrfTokenViaRouter(nodeId) {
        const { data: { token } } = await this.get(
            getBackstageURL(
                nodeId,
                CSRF_TOKEN_ENDPOINT_ROUTER
            ),
            { withCredentials: true }
        );

        return token;
    }

    /**
     * Build URL by interpolating given key value pairs
     *
     * @param {String} url
     * @param {Object} [replacements={}]
     * @returns {String}
     */
    buildUrl(url, replacements = {}) {
        return interpolateUrl(url, replacements);
    }
}
