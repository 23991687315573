{
    "registration": {
        "activation": {
            "accepted_legal_documents": "Accepted legal documents:",
            "activate": "Enable registration",
            "approve_request": "Approve request",
            "bulk_approve_confirmation": "Are you sure you want to approve the selected requests?",
            "bulk_approved_success": "The request have been approved.|All {0} requests have been approved.",
            "bulk_denied_success": "The request have been denied.|All {0} requests have been denied.",
            "bulk_deny_confirmation": "Are you sure you want to deny the selected requests?",
            "created_pax": "Created participant: {0}",
            "deny_request": "Deny request",
            "intro": "Enable registration and build your own custom registration page",
            "messages": {
                "activation_failed": "Unable to activate the registration page. Try again later or contact SpotMe support.",
                "sessions_field": "sessions"
            },
            "reg_url": "Registration page URL",
            "registration_preview": "Registration preview",
            "request_approved": "approved",
            "request_denied": "denied",
            "request_status_change_failed": "Could not update the request status. Try again later. If the problem persists, please contact the support.",
            "request_status_changed_at": "Request {0} on {1}.",
            "request_status_changed_successfully": "The request has been {0} successfully.",
            "title": "Attract new users",
            "url_preview": "Page will be available at: {0}"
        },
        "analytics": {
            "no_pending_users": "No pending registrations",
            "pending_registrations": "Pending registrations",
            "pending_users": "user|users",
            "to_approval_page": "Go to approval page"
        },
        "configuration": {
            "access_code_email_template": "Access code email",
            "access_code_email_template_hint": "Email sent to users that already have an existing account to confirm the access code",
            "access_limitation": "Access limitation",
            "account_updated_email_template": "Profile update confirmation email",
            "account_updated_email_template_hint": "Email sent to attendees after they updated their profile information.",
            "actions": {
                "add_condition": "Add targeting",
                "add_detail_section": "Add section",
                "add_new_exclusion_group": "New exclusion group",
                "add_new_inclusion_group": "New inclusion group",
                "add_section": "Add form",
                "allow_logins": "Show login button",
                "allow_logins_hint": "Event app log in button displayed, if the event is in production.",
                "allow_private_registrations": "Allow invitees to submit and edit their attendance status",
                "allow_private_registrations_hint": "To no longer accept RSVP responses, disable this setting.",
                "allow_profile_updates": "Allow attendees to update their profile information",
                "allow_profile_updates_hint": "E.g. information submitted as part of the registration.",
                "allow_registrations": "Allow attendees to self-register",
                "allow_registrations_hint": "To close the registration, disable this setting.",
                "allow_rsvp_user_limits": "Set attendance capacity",
                "allow_rsvp_user_limits_hint": "As soon as the capacity is reached, registrants will no longer be able to accept your RSVP invitation.",
                "allow_self_user_limits": "Set registration capacity",
                "allow_self_user_limits_hint": "As soon as the capacity is reached, registrants will no longer be able to register on your registration page.",
                "enable_embed": "Make your registration form available on other websites",
                "enable_min_registered_sessions": "Set minimum number of session registrations",
                "enable_min_registered_sessions_hint": "Minimum number of sessions an attendee must register for to successfully register for your event.",
                "enable_self_reg_waiting_list": "Waiting list",
                "registration_status": "Registration status:",
                "remove": "Delete",
                "rename": "Rename",
                "switch_type": "Switch to {0}"
            },
            "add_targeted_email": "+ Send a different email to specific target group",
            "authentication": "Authentication",
            "authentication_type": {
                "email": "Email authentication",
                "email_hint": "Participants may register using an email address",
                "email_sso": "Email authentication & Single Sign-On (SSO)",
                "email_sso_hint": "Participants may register using an email address and SSO credentials",
                "sso": "Single Sign-On (SSO)",
                "sso_hint": "Participants may register using SSO credentials",
                "sso_select": "Select available SSOs"
            },
            "capacity_reached_emails_hint_1": "Email notifications to be sent to the specified email addresses {0}",
            "capacity_reached_emails_hint_2": "when the registration capacity is reached.",
            "confirmation_email_template": "Registration pending email",
            "confirmation_email_template_hint": "Email sent to user registering while manual approval is enabled. Once approved, users will automatically receive Login email.",
            "copy_link": "Copy link",
            "default_email": "Default Email",
            "default_locale": "Default",
            "design": "Design",
            "design_section": {
                "banner": {
                    "hint": "File types: PNG, JPEG. Max file size 10MB. Recommended resolution 2880x692.",
                    "label": "Banner"
                },
                "footer": {
                    "label": "Footer",
                    "opt_label": "Show footer",
                    "text_placeholder": "© YEAR - Name of the company",
                    "tip": "Footer will be visible just in the landing page. 160 characters max. length."
                },
                "heading": "Customize your registration page",
                "logo": {
                    "hint": "File types: PNG, JPEG. Max file size 1MB. Recommended resolution 512x512 px.",
                    "label": "Logo"
                },
                "preview": {
                    "form": "Form",
                    "landing_page": "Landing page"
                },
                "primary_color": "Primary color",
                "register": "Register",
                "show_calendar": {
                    "hint": "Control the visibility of calendar button during registration",
                    "label": "Show Add to calendar buttons",
                    "top_label": "Add to calendar buttons"
                },
                "show_date": {
                    "hint": "Control the visibility of the event dates during registration",
                    "label": "Show dates",
                    "top_label": "Event dates"
                },
                "text_color": "Primary button text color"
            },
            "disable_manual_approval": "Disable manual approval to use this option.",
            "disable_modal": {
                "action": "Disable",
                "text_1": "Be aware that nobody will be able to register for the event through the registration link.",
                "text_2": "Are you sure you want to disable the registration page?",
                "title": "Disable the registration page"
            },
            "email_domains": "Allowed email domains",
            "email_domains_description": "Limit email domains with which attendees can register.",
            "email_target_group": "Email - target group {0}",
            "emails": "Emails",
            "emails_intro": "Customize which emails are sent automatically by SpotMe to attendees when they are registering or accessing the app. You can modify the built-in templates or choose your own below.",
            "embed": "Embedding",
            "embed_alert": "In order to embed this code on your site, we need to enable the domain. Please reach out to our support team to assist you with this. If the domain is not enabled, the embedding code will not work.",
            "embed_code": "Embed code",
            "embed_code_copy": "Copy code",
            "embed_info": "Design options applied in the Design section will be reflected in the embedded form.",
            "event_details": "Page builder",
            "exclusion_groups_intro": "Creating mutually exclusive session groups. Registering for one session in the group will prevent attendees from registering for another sessions within the same group.",
            "form": {
                "add_description": "Add description",
                "add_session_registration": "Add session registration",
                "basic_info": "Basic info",
                "block_list": "All domains except banned ones",
                "capacity_reached_emails": "Registration capacity",
                "datetime_config": {
                    "dates": "Set specific range",
                    "dates_range": "dates range",
                    "enable_dates": "Date picker",
                    "enable_times": "Time picker",
                    "format": "Date format",
                    "time_format": "Time format",
                    "time_range": "time range",
                    "title": "Configure the date and time pickers"
                },
                "description": "Description",
                "domains_listing": "Specify the domains",
                "edit_description": "Edit description",
                "edit_session_registration": "Edit session registration",
                "email_domains": {
                    "any": "Any email domain",
                    "label": "Allow users to register with"
                },
                "email_domains_block_free": "Block free email providers",
                "email_domains_block_placeholder": "competitor1.com, competitor2.com...",
                "email_domains_pass_placeholder": "mycompany.com",
                "less_settings": "Less settings",
                "missing_compatible_fields": "You can only use targeting on fields that are dropdown, single or multiple choice, or checkboxes in the main form",
                "more_settings": "More settings",
                "no_sections": "As the details page does not have any sections; the registration box will be placed in the middle of the screen.",
                "notification_emails_placeholder": "name.surname@domain.com",
                "notifications_emails": "Summary",
                "pass_list": "Only allowed email domains",
                "placeholders": {
                    "presenter": "Choose speakers",
                    "session": "Choose sessions",
                    "sponsor": "Choose sponsors"
                },
                "registration_url_alert": "If you change this link, the old one won't work anymore.",
                "registration_url_label": "Registration page URL",
                "section_types": {
                    "editorial": "Text",
                    "presenter": "Speakers",
                    "session": "Sessions",
                    "sponsor": "Sponsors"
                },
                "sections": {
                    "builder": "Registration page builder",
                    "collapse_items": "Collapsible items",
                    "collapse_items_hint": "Decide how many items to display on the page before the \"Show more\" button.",
                    "show_in_nav": "Show this section title in registration page navigation bar",
                    "show_in_nav_hint": "You can have a maximum of 4 sections displayed in the navigation bar"
                },
                "vivenu_event_id": "Enter your vivenu event ID"
            },
            "form_fields": "Form fields",
            "free_domains_list": "Blocks domains such as gmail.com or yahoo.com. {0)",
            "free_domains_list_link_label": "See list",
            "hint_manage_pending": "You can manage registration requests from {0}.",
            "hint_manage_waiting_list": "You can manage the waiting list from the {0} tab.",
            "inclusion_groups_intro": "Creating mutually inclusive session groups. Registering for one session in the group will automatically register for all the other sessions within the same group.",
            "learn_more": "Learn more",
            "locale": "Locale",
            "login_email_template": "Login email",
            "login_email_template_hint": "Email sent to users logging in, or when their registration request is approved.",
            "manage_pending_link_label": "Users > Pending registrations",
            "manual_approval": "Manual approval",
            "manual_approval_description": "Control who can access your event by adding a manual review step to your registration process.",
            "manual_approval_label": "Manually approve registrations",
            "messages": {
                "event_section_removal": "Are you sure you want to delete this section?",
                "image_does_not_fit": "Could not save. Please crop the image",
                "save_failed": "Could not save the configuration. Try again later or contact the support.",
                "save_success": "Configuration saved"
            },
            "new_event_section_placeholder": "Type a title for this section",
            "new_section_placeholder": "Type a name for the form",
            "new_sessions_group_placeholder": "Type a title for this group",
            "no_exclusion_groups": "There are no exclusion groups",
            "no_i18n": {
                "warning_wall_buttons_hint": "The primary button, labeled '{0}', directs attendees to the registration page, while the secondary button, labeled '{1}', can be customized to link to a different destination specified below.",
                "warning_wall_buttons_hint_accept": "Accept",
                "warning_wall_buttons_hint_reject": "Reject"
            },
            "no_inclusion_groups": "There are no inclusion groups",
            "no_title_set": "- No title set -",
            "notification_emails": "Registration notifications",
            "notifications_1": "Email notifications to be sent to the specified email addresses {0}. The email contains a summary of all new registrations, registration updates and profile updates.",
            "notifications_2": "once per day at 08:00 in the configured timezone",
            "payment": "Ticket purchasing",
            "payment_description": "Ticket purchasing will be required to conclude the registration",
            "payment_label": "Enable ticket purchasing",
            "preview_page": "Open page",
            "reg_types": {
                "hybrid": {
                    "desc": "Combine open registration and personal invitations.",
                    "title": "Public & Private"
                },
                "private": {
                    "desc": "Invite specific people and ask them to confirm their attendance.",
                    "title": "Private - RSVP"
                },
                "public": {
                    "desc": "Anyone with the registration link may register.",
                    "short_title": "Public",
                    "title": "Public - Open registration"
                }
            },
            "registration_confirmation_email_template": "Open registration confirmation email",
            "registration_confirmation_email_template_hint": "Email sent to attendees to confirm that their registration was successfully submitted.",
            "remove_sessions_reg_to_enable": "Remove session registration items from the form to enable this option.",
            "rsvp_accepted_email_template": "RSVP invitation accepted",
            "rsvp_accepted_email_template_hint": "Email sent to attendees when they answer \"Yes\" to an event invitation.",
            "rsvp_declined_email_template": "RSVP invitation declined",
            "rsvp_declined_email_template_hint": "Email sent to attendees when they answer \"No\" to this event invitation.",
            "section_has_anchor": "Visible in nav bar",
            "session_groups_count": "{0} session|{0} sessions",
            "session_registration": "Session registration",
            "settings": "Settings",
            "switch_type": {
                "message": "You can change the registration type for your event at any time. Settings may change from one registration type to another, so please check the settings if you change the registration type.",
                "title": "Switch to {0} registration"
            },
            "title": "Registration",
            "unknown_account_email_template": "Unknown account email",
            "unknown_account_email_template_hint": "Email sent to users trying to log in without registering first.",
            "waiting_list_email_template": "Waiting list email",
            "waiting_list_email_template_hint": "Email sent to users who register after the registration capacity is reached, and they're added to the waiting list. If approved, users will automatically receive Login email.",
            "warning_wall": "Pre-registration confirmation",
            "warning_wall_buttons": "Buttons",
            "warning_wall_buttons_hint": "The labels for the primary and secondary buttons can be edited in the translations manager under the section '{0}'; the keys are '{1}' and '{2}'. The primary button directs attendees to the registration page, while the secondary button's destination site can be customized below.",
            "warning_wall_buttons_hint_accept": "warning_wall_accept",
            "warning_wall_buttons_hint_reject": "warning_wall_reject",
            "warning_wall_buttons_hint_section": "Registration / Actions",
            "warning_wall_description": "A confirmation screen will appear to registrants before they will be able to continue to the registration page. Here you can, for example, ask them to confirm their profession or acknowledge key information.",
            "warning_wall_enabled": "Show pre-registration confirmation",
            "warning_wall_redirect": "Secondary button URL",
            "warning_wall_redirect_hint": "The URL must start with 'https://'",
            "warning_wall_redirect_placeholder": "https://...",
            "warning_wall_text": "Text"
        },
        "sessions_configuration": {
            "available_seat_email": "Available seat email",
            "available_seat_email_hint": "This email is sent to the first user on the waitlist when a seat becomes available.",
            "lease_time": "If an available seat is not claimed, inform the next eligible user in",
            "session_exclusions": "Mutually exclusive sessions",
            "session_import": "Import / export",
            "session_import_page": {
                "export_action": "Export XLS",
                "export_modal": {
                    "title": "Exporting session registrations"
                },
                "export_subtitle": "Export an Excel of the current session registrations records.",
                "export_title": "Export XLS",
                "import_failed": "Session registrations failed to import",
                "import_started": "Import started...",
                "import_subtitle": "Use an Excel file in order to import session registrations or edit existing registrations. The import will ignore any existing session registration or capacity settings that are enabled, including exclusion or inclusion groups.",
                "import_succeeded": "Session registrations successfully imported",
                "import_title": "Import XLS"
            },
            "session_inclusions": "Mutually inclusive sessions",
            "session_reg_settings": "Settings",
            "unclaimed_seat_email": "Seat was not claimed email",
            "unclaimed_seat_email_hint": "This email is sent to the user if they fail to register within the specified time window.",
            "waitlist": "Waitlist",
            "waitlist_intro": "Enable the waitlist feature on individual session pages. When a seat becomes available, the first eligible user will automatically receive an email notification about the newly opened seat."
        }
    }
}
