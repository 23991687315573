{
    "profile": {
        "details": {
            "change_details": "Change your details",
            "change_password": "Change your password",
            "computed": {
                "activated": "Activated?",
                "invited": "Invited to app?",
                "invited_at": "Mail sent on: {0}",
                "pax_activated_at": "Participant device activation on {0}",
                "registration": "Registration"
            },
            "form": {
                "confirm_new_password": "New password (repeat)",
                "current_password": "Current password",
                "email": "Email",
                "email_hint": "Please contact support to change this.",
                "full_name": "Full name",
                "new_password": "New password",
                "password_change_fail": "Password change failed",
                "password_change_reused": "This password cannot be reused",
                "password_change_success": "The password has been changed. Please log in using the new password.",
                "password_tooltip": "Passwords should contain both letters and numbers and are case sensitive.",
                "profile_update_fail": "Something went wrong",
                "profile_update_success": "Your profile has been updated.",
                "wrong_password": "Old password does not match"
            },
            "phone": {
                "enter_code": "Enter the code",
                "invalid_code": "Incorrect code. Please try again",
                "invalid_phone": "Invalid phone number",
                "phone_number": "Phone number",
                "resend_code": "Resend the code",
                "sms_resent": "Code resent to your phone",
                "sms_sent": "A code was sent to your phone",
                "unchanged_phone_number": "Phone number is still the same",
                "update_error": "Could not update the phone number",
                "update_phone_number": "Update phone number",
                "updated": "Your phone number was updated",
                "verification_error": "Could not send the verification code",
                "verify_phone_number": "Verify phone number"
            },
            "save_details": "Save details",
            "strength": {
                "good": "Good",
                "good_job": "Good job!",
                "strong": "Strong",
                "very_weak": "Very Weak",
                "weak": "Weak"
            },
            "title": "Personal details",
            "update_password": "Update password"
        },
        "messages": {
            "picture_unsupported": "File type not supported, please provide one of {0}.",
            "picture_uploaded": "Picture updated successfully.",
            "picture_uploaded_failure": "Could not upload picture. Try again later or contact support."
        },
        "tokens": {
            "actions": {
                "create": "Create a token"
            },
            "form": {
                "name": "Label",
                "name_placeholder": "Name of your integration project",
                "submit": "Create",
                "title": "Create a token"
            },
            "intro": "API tokens allow you to connect SpotMe with third-party services. A token has the same access levels as you and should be treated as any other password.",
            "listing": {
                "app": "Application",
                "created": "Created at",
                "expiration": "Expires",
                "external": "Third-party Service",
                "label": "Label",
                "last_used": "Last used",
                "name": "Label",
                "revoke": "Revoke",
                "revoke_prompt": "Are you sure you want to revoke this token? This action can't be undone."
            },
            "title": "API Tokens",
            "token": {
                "alert": "You will see your token only once. Make sure to copy it before dismissing this screen.",
                "copied": "Token copied into the clipboard.",
                "hint": "Click on the textarea to copy the token",
                "title": "Your token"
            }
        }
    },
    "team_members": {
        "actions": {
            "add": "Add team member",
            "edit": "Edit member",
            "prompt_multiple_removals": "Are you sure that you want to remove all the selected team members?",
            "prompt_single_removal": "Are you sure that you want to remove {0} as team member?",
            "remove_member": "Remove team member",
            "remove_members": "Remove team member|Remove team members",
            "selection": "Team members selected"
        },
        "form": {
            "access_role": "Access role",
            "editor_hint": "Limitations regarding managing settings, production status and sending emails.",
            "email_address_tip": "If a user account does not exist for this email address one will be created.",
            "manager_hint": "Full access, no restrictions.",
            "viewer_hint": "Can view and export content; can view analytics."
        },
        "list": {
            "headers": {
                "email": "Email",
                "name": "Full name",
                "role": "Access role"
            }
        },
        "messages": {
            "all_removed": "All team members removed",
            "created": "Team member successfully created",
            "edited": "Access role changed successfully",
            "permission_error": "You do not have sufficient rights to create a new user account.",
            "permission_error_user_not_in_org": "This user is not a Member in the Organization connected to this Workspace. In order to add this user to the Workspace, please go to the parent Organization and add this user as an Organization Member first.",
            "single_removed": "Team member removed successfully"
        },
        "roles": {
            "editor": "Editor",
            "manager": "Manager",
            "revoked": "Revoked",
            "viewer": "Viewer"
        },
        "title": "Team members"
    },
    "user_display": {
        "access_spotme_webapp": "Access SpotMe Web App",
        "access_the_app": "Access the app",
        "access_webapp_as": "Access web app as {0}",
        "actions": "Actions",
        "activation": {
            "accepted_legal_documents": "Legal Document",
            "activated_on": "Activated on",
            "activation_id": "Record ID",
            "activation_uuid": "UUID",
            "app_version": "App version",
            "branding": "Branding",
            "bundle_id": "Bundle ID",
            "device": "Device",
            "device_id": "Device ID",
            "node": "Node",
            "os_version": "OS version",
            "platform": "Operating system",
            "user_agent": "User agent"
        },
        "activity_feed": "Activity feed",
        "close_and_return": "Close and return",
        "confirm_send_email": "Are you sure you want to send this email?",
        "email_not_sent": "The email could not be sent",
        "email_sent": "Email sent",
        "emails": {
            "unsub": "This user unsubscribed from emails",
            "unsub_on": "on"
        },
        "event_password": "Event password",
        "install_app": "Install the mobile app",
        "log_in_as": "Log in as {0}",
        "mail_delivered": "Mail delivered: {0}",
        "mobile_activation": "Mobile activation",
        "or_share_the_link": "or share the link",
        "participant_record_updated": "Participant record updated",
        "prepare_badge_print_for": "Prepare badge print for {0}",
        "quick_stats": "Quick stats",
        "registered_sessions": "Registered sessions",
        "remove": "Remove",
        "replace": "Replace",
        "scan_qr_code": "Scan the QR code",
        "targeted_sessions": "Targeted sessions",
        "upload_photo": "Upload photo",
        "use_mobile_app_url": "Use the URL below to install the Mobile app",
        "web_activation": "Web activation"
    },
    "user_management": {
        "delete": {
            "already_deleted": "This user has been deleted",
            "button": "Delete user",
            "confirm": "Are you sure you want to delete this user?",
            "notify_error": {
                "text": "Could not delete {0}",
                "title": "Error"
            },
            "notify_success": {
                "text": "{0} was sucessfully deleted",
                "title": "Deleted"
            }
        },
        "grants": {
            "denied": "role denied",
            "failed": "Could not grant <em>{0}</em> role to <strong>{1}</strong>",
            "failed_title": "An error occurred",
            "granted": "role granted",
            "success_granted": "Role <em>{0}</em> has been granted to <strong>{1}</strong>",
            "success_granted_title": "Role granted",
            "success_revoked": "Role <em>{0}</em> has been revoked from <strong>{1}</strong>",
            "success_revoked_title": "Role revoked"
        },
        "roles": {
            "app_publisher": "App Publisher",
            "beta_tester": "Beta Tester",
            "developer": "Developer",
            "spotme": "SpotMe",
            "spotme_am": "Account Manager",
            "spotme_csm": "Customer Success Manager",
            "spotme_finance": "Finance",
            "spotme_product": "Product",
            "spotme_support": "Support",
            "superadmin": "Superadmin"
        },
        "search": "Search",
        "title": "Backstage user roles"
    },
    "users": {
        "actions": {
            "schedule": "Schedule",
            "send": "Send"
        },
        "email_modal": {
            "confirmation_body": "You are about to send emails to {1}. There are {0} such users. Proceed?",
            "invited_targeting": "Invited to event (RSVP)",
            "navigate_to_scheduled": "View {0} scheduled emails",
            "no_production": {
                "body": "You are sending an email to more than one user for an event that is in a pre-production status. Are you sure you want to proceed?",
                "body_test_event": "To send emails to more than one user, event has to have preproduction or production status.",
                "go_to_settings": "Go to settings",
                "title": "Non-production event!",
                "title_test_event": "Test event"
            },
            "other_email": "Another email is scheduled to be sent out at this time:",
            "schedule_toggler": "Schedule for later",
            "selected": "Users selected",
            "target": "Target",
            "target_placeholder": "Choose target",
            "targets": {
                "active": "Active users",
                "all": "All users",
                "cancelled": "Cancelled users",
                "inactive": "Inactive users",
                "mailertarget1": "Mailertarget1",
                "mailertarget2": "Mailertarget2",
                "mailertarget3": "Mailertarget3",
                "not_invited": "Not invited to app",
                "pending": "Invited to app - not activated",
                "rsvp_not_invited": "RSVP - not invited",
                "self-reg-unverified-email": "Registered, not activated",
                "targeted": "Custom targeting"
            },
            "template": "Template",
            "template_placeholder": "Choose template",
            "time": "Time",
            "title": "Send email",
            "workspace_timezone": "{0} Timezone (workspace)"
        },
        "exports": {
            "fields": "Select additional fields to export",
            "fields_hint": "Mandatory fields are already included"
        }
    }
}
