// Utils
import axios from 'axios';
import { hashObject } from 'libs/utils/objects';
import { get, set } from 'lodash';

// Constants

/**
 * How long (in milliseconds) we keep long lasting API requests cached
 * Currently 5 minutes.
 * @constant {number} XHR_CACHE_DURATION
 */
//                           ms    s   m
const XHR_CACHE_DURATION = 1000 * 60 * 5;

// XHR library global configuration
axios.defaults.withCredentials = false;

/**
 * This is the prototype of any service.
 *
 * On purpose it does import the bare minimum in order to keep it
 * sleek and free from clutter and independent from the rest of
 * the architecture.
 */
export default class ServicePrototype {

    constructor() {
        this.__CACHE = {};
    }

    /**
     * How long (in milliseconds) we keep long lasting API requests cached
     * Currently 5 minutes.
     * @constant {number} XHR_CACHE_DURATION
     */
    get XHR_CACHE_DURATION() {
        return XHR_CACHE_DURATION;
    }

    /**
     * Async HTTP head request
     *
     * @param {String} url the server URL that will be used for the request
     * @param {Object} [config] optional configuration for making requests
     *
     * @returns {Promise<import("axios").AxiosResponse>} the server response
     */
    head(url, config) {
        return axios.head(url, config);
    }

    /**
     * Async HTTP get request
     *
     * @param {String} url the server URL that will be used for the request
     * @param {Object} [config] optional configuration for making requests
     *
     * @returns {Promise<import("axios").AxiosResponse>} the server response
     */
    async get(url, config) {
        return axios.get(url, config);
    }

    /**
     * Async HTTP post request
     *
     * @param {String} url the server URL that will be used for the request
     * @param {Object} data the data to be sent as the request body
     * @param {Object} [config] optional configuration for making requests
     *
     * @returns {Promise<import("axios").AxiosResponse>} the server response
     */
    post(url, data, config) {
        return axios.post(url, data, config);
    }

    /**
     * Async HTTP put request
     *
     * @param {String} url the server URL that will be used for the request
     * @param {Object} data the data to be sent as the request body
     * @param {Object} [config] optional configuration for making requests
     *
     * @returns {Promise<import("axios").AxiosResponse>} the server response
     */
    put(url, data, config) {
        return axios.put(url, data, config);
    }

    /**
     * Async HTTP patch request
     *
     * @param {String} url the server URL that will be used for the request
     * @param {Object} data the data to be sent as the request body
     * @param {Object} [config] optional configuration for making requests
     *
     * @returns {Promise<import("axios").AxiosResponse>} the server response
     */
    patch(url, data, config) {
        return axios.patch(url, data, config);
    }

    /**
     * Async HTTP delete request
     *
     * @param {String} url the server URL that will be used for the request
     * @param {Object} [config] optional configuration for making requests
     *
     * @returns {Promise<import("axios").AxiosResponse>} the server response
     */
    delete(url, config) {
        return axios.delete(url, config);
    }

    /**
     * Performs a cached GET request to the server
     *
     * @param {String} url the server URL that will be used for the request
     * @param {Object} [config] optional configuration for making requests
     * @param {Number} [maxDuration=XHR_CACHE_DURATION] the max duration of the cache in milliseconds
     *
     * @returns {Promise<import("axios").AxiosResponse>} the server response
     */
    async getCached(url, config = {}, maxDuration = XHR_CACHE_DURATION) {
        return this.cachedRequest(url, 'get', null, config, maxDuration);
    }

    /**
     * Performs a cached POST request to the server
     *
     * @param {String} url the server URL that will be used for the request
     * @param {Object} data the data to be sent as the request body
     * @param {Object} [config] optional configuration for making requests
     * @param {Number} [maxDuration=XHR_CACHE_DURATION] the max duration of the cache in milliseconds
     *
     * @returns {Promise<import("axios").AxiosResponse>} the server response
     */
    async postCached(url, data, config = {}, maxDuration = XHR_CACHE_DURATION) {
        return this.cachedRequest(url, 'post', data, config, maxDuration);
    }

    /**
     * Makes a cached request to the specified URL using the specified method and data.
     * If the request has been cached and is still valid, the response is returned from the cache.
     * Otherwise, the request is sent to the server and the response is cached for future use.
     *
     * @param {string} url - The URL to make the request to.
     * @param {string} method - The HTTP method to use for the request (e.g., 'get', 'post').
     * @param {Object} data - The data to send with the request (optional).
     * @param {Object} config - Additional configuration options for the request (optional).
     * @param {number} maxDuration - The maximum duration (in milliseconds) to cache the response (optional).
     *
     * @returns {Promise} A Promise that resolves to the response of the request.
     */
    async cachedRequest(url, method, data, config = {}, maxDuration = XHR_CACHE_DURATION) {
        // requires credentials for cors
        config.withCredentials = true;

        // Here we `await` for a possible stored promise (in case of a race condition)
        const dataHash = data ? hashObject(data) : 'nodata';
        const cacheKey = `${method}-${url}-${dataHash}`;

        if (await this.isRequestCached(cacheKey, maxDuration)) {
            console.debug(`[${this.constructor.name}] Serving "${method}" response from the cache`, url);
            return this.__CACHE[cacheKey];
        }

        console.debug(`[${this.constructor.name}] Cached "${method}" request not found or expired, hitting the server`, url);

        // Here we don't `await` in order to handle racing conditions.
        // We store the Promise as soon as we get the request without blocking the execution.
        if (method === 'get') {
            this.__CACHE[cacheKey] = this.get(url, config);
        } else if (method === 'post') {
            this.__CACHE[cacheKey] = this.post(url, data, config);
        }

        return this.__CACHE[cacheKey];
    }

    /**
     * Checks if a request is cached and not expired.
     *
     * @param {string} cacheKey - The key used to identify the cached request.
     * @param {number} maxDuration - The maximum duration in milliseconds for which the cached request is considered valid.
     *
     * @returns {boolean} - Returns true if the request is cached and not expired, otherwise returns false.
     */
    async isRequestCached(cacheKey, maxDuration) {
        let cached = this.__CACHE[cacheKey];
        if (cached) {
            cached = await cached;
            const currentTime = Date.now();

            // Even if we find a cached request we still have to check if it's not expired.
            const date = get(cached, 'headers.date');
            const reqTime = date ? new Date(date).getTime() : currentTime;

            if (!date) {
                // If no date was set, store for next time
                set(cached, 'headers.date', currentTime);
            }

            if (currentTime - reqTime <= maxDuration) {
                return true;
            }

            delete this.__CACHE[cacheKey]; // Remove expired cache
        }

        return false;
    }

    /**
     * Forcefully remove an url from the cache.
     * @param {string} url
     */
    bustCache(url) {
        delete this.__CACHE[url];
    }

    /**
     * Build URL by interpolating given key value pairs
     *
     * @param {String} url
     * @param {Object} [replacements={}]
     * @returns {String}
     */
    buildUrl(url, replacements = {}) {
        return Object.keys(replacements).reduce((acc, key) => acc.replace(`{{${key}}}`, replacements[key]), url);
    }
}
