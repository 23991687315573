{
    "api_docs": {
        "back": "Backstage",
        "back_to_backstage": "Login to Backstage",
        "base_url": "Base URL:",
        "base_url_tooltip": "Click to copy",
        "data_types": {
            "consumes": "Input data types",
            "produces": "Output data types",
            "title": "Data types"
        },
        "empty_tags": "No API available",
        "export": "Export OpenAPI",
        "models": "Models",
        "packages": {
            "picker": {
                "name": "Pick a package",
                "version": "Pick a version"
            }
        },
        "params": {
            "key": "Name",
            "none": "No parameters",
            "required": "* required",
            "title": "Parameters",
            "value": "Description"
        },
        "responses": {
            "code": "Code",
            "description": "Description",
            "title": "Responses"
        },
        "routes": "Routes",
        "schema": {
            "desc": "Description",
            "example": "Example",
            "example_value": "Example value",
            "less": "Less details",
            "model": "Model",
            "more": "More details",
            "prop": "Property",
            "see_documentation": "See documentation",
            "type": "Type"
        },
        "security": {
            "name": "Name",
            "title": "Authentication",
            "type": "Type",
            "where": "Context"
        },
        "url_copied": "The URL has been copied into the clipboard"
    }
}
