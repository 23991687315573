{
    "metadata_editor": {
        "add_category": "Add category",
        "add_category_prompt": "Enter the category name",
        "add_choice": "Add choice",
        "add_field": "Add field",
        "allow_multiple_docs": "Allow multiple selections",
        "can_not_delete_category": "Category deletion restricted",
        "can_not_delete_category_text": "This category cannot be deleted because it contains fields you are not allowed to remove (identified by a lock icon next to the field name). Please move these fields to another category before attempting to delete.",
        "category": "Category",
        "choice_list": "choice list",
        "default": "Default",
        "default_value": "Preview & default value",
        "delete_category": "Delete category",
        "delete_category_success": "Category deleted successfully",
        "delete_category_text": "Are you sure you want to delete this category? All the metadata inside this category will be deleted as well",
        "delete_field": "Delete field",
        "edit_choice_list": "Edit",
        "edit_options": "Edit options",
        "ensure_referential_integrity": "Check references",
        "enter_category_name": "Type a name for the category",
        "errors": {
            "fetch_fail": "Failed to fetch appropriate metadata"
        },
        "false": "false",
        "field_kind": "Field kind",
        "field_may_not_be_private": "The following fields may not be private: {0}.",
        "field_name": "Field name",
        "field_name_tooltip": "Spaces and special characters are not allowed, except underscores, which cannot be the first character",
        "fields": "Fields",
        "fields_hidden": "Editing {0}'s metadata is forbidden",
        "fields_limit_info": "Fields limit: {0} of {1}",
        "fields_limit_reached": "You have reached the limit of {0} fields. If you need more, please open a support request.",
        "general_warning": {
            "knowledge_base": "Knowledge base",
            "line_1": "This tool can make irreversible changes to the way data is structured in this event instance.",
            "line_2": "Be careful! Learn more about the metadata manager in our "
        },
        "has_been_modified": "Metadata have been updated while you were working on them. Please take note of your changes and refresh the page",
        "invalid_data": "Invalid data",
        "invalid_field_name": "Invalid field name: {0}|Invalid field names: {0}",
        "is_required": "Is required",
        "label": "Label",
        "label_and_tooltip": "Label, tooltip & placeholder",
        "metadata": "metadata",
        "move_down": "Move down",
        "move_to_top": "Move on top",
        "move_up": "Move up",
        "no_category": "Base data",
        "no_default_value": "No default value",
        "no_fields_in_category": "There are no fields in this category",
        "no_options_of_type": "There are no {0}s to choose from",
        "ok": "Ok",
        "placeholder": "Placeholder",
        "private": "Private",
        "private_tip": "If enabled this field will be visible to other participants only after contact request is accepted.",
        "remove": "Remove",
        "representation": {
            "_representation": "First line",
            "_representation_image": "Image",
            "_representation_line2": "Second line",
            "_representation_line3": "Third line",
            "description": "Representation labels describe how items of this type are named in the UI. Words wrapped in {0} and {1} will be replaced with field values."
        },
        "restore": "Restore",
        "save_changes": "Save changes",
        "save_success": "Your changes have been successfully saved",
        "switch": "Switch to the old editor",
        "titles": {
            "page": "Metadata Manager",
            "representation": "Representation"
        },
        "tooltip": "Tooltip",
        "true": "true",
        "unknown_save_error": "An unknown error occured.",
        "value": "Value",
        "write_protected": "Prevent in app modification"
    }
}
