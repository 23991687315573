{
    "wizards": {
        "create_template": {
            "action": "Create template",
            "creation_progress": "This may take a few minutes",
            "creation_title": "Creating template",
            "errors": {
                "job_creation_failed": "Unable to complete the template creation due to a server error.",
                "job_registration": "Unable to register creation job."
            },
            "form": {
                "category": "Category",
                "category_placeholder": "Choose a category",
                "description": "Short description",
                "description_placeholder": "Add a short description to your template",
                "icon": "Icon",
                "icon_hint": "Recommended size is 512 × 512px",
                "image_load_failed": "image loading failed, please retry",
                "long_description": "Long description",
                "long_description_placeholder": "Add a long description for your template",
                "name": "Name",
                "name_placeholder": "Choose name",
                "order": "Listing order",
                "order_placeholder": "Set an order number",
                "order_tip": "This option is only available for 'global' templates",
                "organization": "Choose organization",
                "organization_hint": "Choose the organization you want to create the new template for.",
                "organization_placeholder": "Choose organization",
                "parent_type": "Based on",
                "parent_type_template": "Template",
                "parent_type_workspace": "Workspace",
                "screenshots": "Screenshots",
                "screenshots_hint": "Recommended size is 1920 × 1080px (maximum 4 images)",
                "servers_location": "Data storage",
                "servers_location_hint": "Choose the region where you want to store the template data.",
                "servers_location_placeholder": "Choose region",
                "template_parent_placeholder": "Template / Workspace",
                "visibility": "Visibility",
                "visibility_options": {
                    "global": "Global - Available to everyone",
                    "private": "Private - Available to team members only",
                    "public": "Public - Available to the whole organization"
                }
            },
            "main_title": "New template"
        },
        "create_webinar_template": {
            "action": "Create template",
            "form": {
                "name": "Template name"
            },
            "main_title": "New template",
            "step_1_title": "General & Interactivity",
            "step_2_title": "Design",
            "step_3_title": "Registration page",
            "step_4_title": "Integrations",
            "step_5_title": "Post-event form",
            "template_created": "Webinar template successfully created.",
            "template_creation_failed": "There was a problem creating the webinar template."
        },
        "create_workspace": {
            "action": "Create workspace",
            "cards": {
                "card-1": {
                    "description": "Install the app and see how your workspace looks from the app user's perspective.",
                    "link-label": "See more",
                    "link-url": "http://sl.spotme.com/download-app",
                    "media": "wizards/create-workspace/image-1.svg",
                    "title": "Download the app"
                },
                "card-2": {
                    "description": "Read our guidelines on how to get started with your first workspace.",
                    "link-label": "See more",
                    "link-url": "http://sl.spotme.com/getting-started",
                    "media": "wizards/create-workspace/image-2.svg",
                    "title": "First steps"
                },
                "card-3": {
                    "description": "Find out what updates have been released for Backstage and the app.",
                    "link-label": "See more",
                    "link-url": "http://sl.spotme.com/whats-new",
                    "media": "wizards/create-workspace/image-3.svg",
                    "title": "What's new?"
                }
            },
            "creation_progress": "This may take a few minutes",
            "creation_title": "Creating workspace",
            "errors": {
                "job_creation_failed": "Unable to complete the workspace creation due to a server error.",
                "job_registration": "Unable to register creation job."
            },
            "event_type": "Event type",
            "filters": {
                "all": "All templates",
                "global": "SpotMe templates",
                "user": "My templates"
            },
            "form": {
                "anonymization_domains": "Select the email domains of the users you would like to anonymize",
                "anonymization_domains_placeholder": "mycompany.com",
                "anonymization_enabled": "Behavioral data collected about your users will be anonymized.",
                "anonymize_all_users": "Anonymize all users' data",
                "anonymized": "Anonymization ",
                "audience_types": {
                    "external": "External",
                    "internal": "Internal",
                    "mixed": "Mixed"
                },
                "category_values_placeholder": "Write a value and press enter",
                "city": "Location",
                "city_tip": "Enter the location where the event will be hosted. This can be a city, venue or online.",
                "container_app": "Default app to host this workspace",
                "container_app_hint": "This workspace will be visible only in this app",
                "container_app_placeholder": "Select a container app",
                "country": "Country",
                "country_placeholder": "Select country",
                "country_tip": "Select the country where the event will be hosted. You can select more than one if relevant.",
                "end_date": "Ends at",
                "end_date_hint": "Once the end date has passed you will no longer be able to change these dates.",
                "event_custom_classification": "Custom type",
                "event_formats": {
                    "hybrid": "Hybrid",
                    "in_person": "In person",
                    "virtual": "Virtual"
                },
                "event_scopes": {
                    "global": "Global",
                    "local": "Local",
                    "regional": "Regional"
                },
                "expected_attendances": {
                    "100": "50-100",
                    "1000": "500-1000",
                    "2500": "1000-2500",
                    "2501": "over 2500",
                    "49": "<50",
                    "500": "100-500"
                },
                "fieldsets": {
                    "anonymization": "Data anonymization",
                    "app": "Container app",
                    "audience_type": "Audience type",
                    "audience_type_tip": "Choose 'Internal' if more than 60% of attendees are from within the organization, 'External' if more than 60% are from outside, and 'Mixed' if the distribution is close to 50/50 between internal and external participants.",
                    "details": "Workspace details",
                    "event_classification": "Event type",
                    "event_format": "Event format",
                    "event_scope": "Scope of the event",
                    "event_specs": "Event specifications",
                    "expected_attendance": "Expected attendees",
                    "extra": "Test workspace",
                    "privacy": "Privacy",
                    "venue": "Location and time zone"
                },
                "name": "Workspace name",
                "not_anonymized": "Not anonymized",
                "one_shot_setting": "It's not possible to change this setting once the workspace has been created.",
                "organization": "Create for",
                "organization_placeholder": "Select an organization",
                "privacy_documents": "Privacy policies",
                "privacy_documents_placeholder": "Choose polices",
                "privacy_intro": "Users will have to accept requirements specified in the documents selected below before accessing the workspace. At least one privacy policy must be selected to create a workspace.",
                "servers_location": "Data location",
                "servers_location_placeholder": "Select a server location",
                "start_date": "Starts at",
                "test_workspace": "This is a test workspace",
                "test_workspace_hint": "Check this box if this workspace is used for testing, training, or demo purposes. Seats and other entitlements will not be consumed in this workspace.",
                "test_workspace_hint_member": "Check this box if this workspace is used for testing, training, or demo purposes. Seats and other entitlements will not be consumed in this workspace. You need to be an Admin to create a production workspace.",
                "timezone": "Time zone",
                "timezone_placeholder": "Time zone"
            },
            "location_and_timezone": "Location & timezone",
            "main_title": "New workspace",
            "step_1_title": "Template",
            "step_2_title": "Workspace information",
            "step_3_title": "Summary",
            "summary": {
                "alerts": {
                    "module_install": "The following paid modules will be installed with the template:",
                    "paid_modules": "The template you have selected uses paid modules",
                    "users_hint": "Contact support to request more after your workspace is created.",
                    "users_limit": "Up to 2,500 simultaneous users"
                },
                "edit": "Edit",
                "fields": {
                    "categories": "Categories",
                    "category": "Category",
                    "city": "Location",
                    "container_app": "Container app",
                    "country": "Country",
                    "end_date": "Ends at",
                    "name": "Workspace name",
                    "organization": "Organization",
                    "privacy_documents": "Privacy documents",
                    "servers_location": "Data location",
                    "start_date": "Starts at",
                    "template": "Template",
                    "test_workspace": "Test workspace",
                    "timezone": "Time zone",
                    "users": "Users"
                },
                "intro": "That's all. Please review the below summary of the workspace information. Select Edit to make any necessary changes.",
                "title": "Workspace information"
            },
            "templates_title": "Select a template"
        },
        "errors": {
            "no_data": "Can't process the wizard without data."
        },
        "next": "Next",
        "prev": "Back"
    }
}
