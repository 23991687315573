export default {
    data() {
        return {
            hasNoChildren: false,
            observer: null,
            ref: 'fieldset'
        };
    },

    watch: {
        hasNoChildren(value) {
            this.$emit('empty', value);
        },

        asd() {
            this.checkEmptiness();
        }
    },

    created() {
        this.observer = new MutationObserver((mutationList) => {
            for (const mutation of mutationList) {
                if (mutation.type === 'childList') {
                    console.log(`[${this.$options.name}] A child has been added or removed.`);
                    this.checkEmptiness();
                }
            }
        });
    },

    mounted() {
        // Options for the observer (which mutations to observe)
        const config = { attributes: false, childList: true, subtree: false };
        this.observer.observe(this.$refs[this.ref], config);

        this.checkEmptiness();
    },

    beforeDestroy() {
        if (typeof this.observer?.disconnect === 'function') {
            this.observer.disconnect();
        }
    },

    methods: {
        checkEmptiness() {
            this.hasNoChildren = this.isEmptyFieldset();
        },

        isEmptyFieldset() {
            return this.$refs[this.ref]?.childElementCount === 0;
        }
    }
};
