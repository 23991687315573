import { default as agenda } from './agenda.json';
import { default as analytics } from './analytics.json';
import { default as emails } from './emails.json';
import { default as general } from './general.json';
import { default as live_sessions } from './live_sessions.json';
import { default as marketplace } from './marketplace.json';
import { default as metadata_editor } from './metadata_editor.json';
import { default as onsite_scanning } from './onsite_scanning.json';
import { default as organization } from './organization.json';
import { default as registration } from './registration.json';
import { default as session_check_in } from './session_check_in.json';
import { default as users } from './users.json';
import { default as wizards } from './wizards.json';

const en = {
    ...agenda,
    ...analytics,
    ...emails,
    ...general,
    ...live_sessions,
    ...marketplace,
    ...metadata_editor,
    ...onsite_scanning,
    ...organization,
    ...registration,
    ...session_check_in,
    ...users,
    ...wizards
};

export { en };
