{
    "onsite_scanning": {
        "design": {
            "layout_dpi": "Dots per inch",
            "layout_height": "Page layout height",
            "layout_name": "Design name",
            "layout_preset": "Badge type",
            "layout_unit": "Page format unit",
            "layout_width": "Page layout width",
            "messages": {
                "bg_not_fitting": "Please provide an image with a resolution of {0}x{1} px for an optimal result."
            }
        },
        "settings": {
            "background_color": {
                "label": "Background color",
                "tip": "If the uploaded background image cannot be used, the chosen fill color will be used instead."
            },
            "bg": {
                "hint": "Recommended size: 2732x2048px",
                "label": "Background"
            },
            "buttons": {
                "primary_button_text_color": {
                    "label": "Primary button icon"
                },
                "secondary_button_background_color": {
                    "label": "Secondary button background",
                    "tip": "Secondary buttons are visible even on tables with a default white background. Please choose a color that remains effective in this scenario as well."
                },
                "secondary_button_text_color": {
                    "label": "Secondary button text"
                }
            },
            "checkin": {
                "attendee": "Attendee check-in",
                "exception": "Exception desk check-in",
                "host": "Host check-in"
            },
            "layout": {
                "label": "Background image"
            },
            "logo": {
                "hint": "Recommended size: 600x120px for horizontal, 300x300px for square and 150x600 for vertical logos",
                "label": "Logo"
            },
            "messages": {
                "save_failure": "Could not save the settings. Try again later or contact the support.",
                "save_failure_required_fields": "The form submitted is invalid. Please verify the form fields for errors.",
                "save_success": "Settings successfully updated."
            },
            "metadata_display_fields": {
                "label": "Metadata to display on the list"
            },
            "metadata_match": {
                "label": "Search method"
            },
            "metadata_printing": {
                "label": "Metadata to be displayed on the check-in verification screen"
            },
            "metadata_search_fields": {
                "label": "Metadata to search by"
            },
            "primary_font": {
                "hint": "File name must not contain special characters nor spaces.",
                "label": "Primary font"
            },
            "printer": {
                "add_design": "Add another design",
                "badge_design": "Badge design",
                "design": "Fonts for ZD621",
                "design_intro": "Upload up to two fonts that will be used in your badge template. If no fonts are uploaded, the default printer's fonts will be utilized.",
                "designs": {
                    "label": "Default badge design|Badge design {0}",
                    "section_removal": "Are you sure you want to delete this section?",
                    "section_removal_title": "Delete badge design reference"
                },
                "not_visible_desc": "Participants matching these rules will be excluded for badges printed using this code",
                "not_visible_label": "Not used for",
                "pax_print_quantity": {
                    "hint": "Exception desk can always reprint attendees' badges and is not limited by this setting",
                    "label": "Number of badges that can be printed per attendee"
                },
                "pick_design": "Select a design",
                "printer_config": "Settings",
                "printer_kind": {
                    "label": "Choose your type of printer"
                },
                "skip_printing": {
                    "label": "Allow to skip badge printing in the kiosk"
                },
                "visible_desc": "Participants matching these rules will be included for badges printed using this code",
                "visible_label": "Used for"
            },
            "printing_enabled": {
                "label": "Badge printing"
            },
            "scanning_enabled": {
                "label": "Scan QR code"
            },
            "search_enabled": {
                "label": "Search"
            },
            "secondary_font": {
                "hint": "File name must not contain special characters nor spaces.",
                "label": "Secondary font"
            },
            "text_color": {
                "label": "Text color"
            }
        }
    }
}
