{
    "agenda": {
        "check_in_status": {
            "checked_in_people": "Pax checked in",
            "checked_out_people": "Pax checked out",
            "expected_people": "Pax expected",
            "qr_scans_title": "QR Stats",
            "scans_count": "Scans count",
            "waiting_people": "Pax not yet checked in"
        },
        "forms": {
            "configure_form": "Configure form",
            "configure_form_link": "Configure form's schedule.",
            "delivery_delta_minutes": "Delivery delta",
            "delivery_delta_minutes_tooltip": "Deliver the form X minutes before/after 'Deliver form after' property",
            "expire_property": "Remove form after",
            "is_in_details": "Show in page details",
            "is_in_details_tooltip": "Visible in page details",
            "is_notification_enabled": "Send notification",
            "is_notification_enabled_tooltip": "If checked notification will be send to targeted participants upon delivering the form",
            "is_visible_after_submitting": "Keep the form visible after the user submits their response",
            "page_name": "Name",
            "sent_property": "Deliver form after"
        },
        "messages": {
            "session_added_success": "Track session \"{0}\" added successfully.",
            "session_removed_success": "Track session \"{0}\" removed successfully.",
            "session_updated_failure": "Track session could not be updated. Try again later. If the problem persists contact the support."
        },
        "session": {
            "close_registration": "Close registration",
            "download_reg_report": "Download registrations report",
            "download_reg_report_title": "Export session registrations",
            "open_registration": "Open registration",
            "reg_closed": "closed",
            "reg_open": "open",
            "registered": "Registered attendees",
            "registration_state_changed": "Registration {0} successfully.",
            "registrations": "Status",
            "slots": {
                "placeholder": "Choose track sessions"
            },
            "waitlisted": "Waitlist"
        }
    }
}
