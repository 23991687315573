{
    "organization": {
        "details": {
            "account_manager_options": "Account Manager Options",
            "allow_non_unique_email": "User creation with shared email addresses",
            "allow_non_unique_email_tooltip": "Remember that when you turn this setting on, you are removing the requirement for the attendees’ email address to be unique.",
            "bs_is_blocked": "Is Blocked",
            "bs_is_spotme": "Is SpotMe Org",
            "bs_is_spotme_tooltip": "SpotMe orgs are excluded from license checks and some email notifications.",
            "content_hubs_enabled": "Content hubs enabled",
            "display_name": "Organization name",
            "enable_anonymization": "Enable Behavioral Data Anonymization",
            "enable_rtmps_slides": "Enable Slides in RTMPs",
            "entitlements_enabled": "Entitlements Enabled",
            "entitlements_v2": "Auto Entitlements",
            "hive": "Hive",
            "hive_customer_id": "Customer ID",
            "hive_partner_id": "Partner ID",
            "hive_partner_token": "Partner Token",
            "life_science": "Life science",
            "name": "Organization",
            "org_industry": "Organization industry",
            "other": "Other industries",
            "paid_packages": "Paid packages",
            "password_rotation_policy": "Password Rotation Policy",
            "password_rotation_policy_duration": "Duration (seconds)",
            "sf_account_id": "Account ID",
            "sf_account_id_tooltip": "The Salesforce Account ID",
            "show_live_captions": "Show Live Captions",
            "show_studio_express": "Show Spotme Express",
            "studio_use_chime": "Always use AWS Chime in Studio",
            "superadmin_options": "Superadmin Options",
            "support_options": "Support Options",
            "update_error": "Failed to save organization details",
            "update_success": "Organization details saved"
        },
        "pax_management": {
            "bulk": {
                "cancel": "Cancel user",
                "cancel_confirm": "Are you sure you want to cancel {0} in all their workspaces? They will be no longer able to login into any of them.",
                "cancel_failure": "Failed to cancel sessions: {0}",
                "cancel_success": "User was canceled successfully from all workspaces",
                "delete": "Delete",
                "log_out": "Log out",
                "log_out_confirm": "Are you sure you want to invalidate {0} in all their workspaces?",
                "log_out_failure": "Failed to invalidate sessions {0}",
                "log_out_success": "All sessions were invalidated"
            },
            "deleted_user": {
                "message": "This user has requested their data to be deleted on {0}",
                "title": "Deleted user"
            },
            "sections": {
                "privacy": {
                    "accepted_policy": "Accepted {0} privacy policy",
                    "accepted_policy_with_name": "{0} accepted {1} privacy policy",
                    "action": "Action",
                    "fetch_failure": "Failed to fetch accepted legal documents.",
                    "nothing_found": "No accepted legal documents found for this email.",
                    "time": "Time",
                    "title": "Privacy",
                    "unsubscribed": "Unsubscribed from marketing emails on workspace: {0}"
                },
                "workspaces": {
                    "activation": "Activation time",
                    "device": "Device",
                    "fetch_failure": "Failed to fetch user sessions.",
                    "headers": {
                        "full_name": "Full name",
                        "workspace": "Workspace"
                    },
                    "no_sessions": "No sessions found for this event.",
                    "nothing_found": "No sessions found for this email.",
                    "session_actions": {
                        "ban": "Cancel user from workspace",
                        "ban_confirm": "Are you sure you want to cancel {0} from accessing {1}?",
                        "ban_failure": "Failed to cancel workspace sessions",
                        "ban_success": "Workspace sessions were cancelled",
                        "go_to_user": "Go to user profile",
                        "kick": "Invalidate user session",
                        "kick_confirm": "Are you sure you want to invalidate {0} sessions in {1}?",
                        "kick_failure": "Failed to invalidate workspace sessions",
                        "kick_success": "Workspace sessions were invalidated",
                        "view_all": "View all sessions"
                    },
                    "sessions_title": "Sessions",
                    "title": "Workspaces",
                    "user_cancelled": "User cancelled"
                }
            }
        },
        "sales_contact": {
            "your_representative": "Your Sales Representative"
        },
        "workspaces": {
            "form": {
                "activation_codes": "Event passwords",
                "activation_codes_hint": "You need to enable event passwords for your organization in order to use them in your workspaces.",
                "category_label": "Category label",
                "category_multiselect": "Multiselect",
                "category_values": "Category values",
                "category_values_placeholder": "Add a category value",
                "container_app": "Container app",
                "container_app_hint": "This workspace will be visible only in this app",
                "container_app_placeholder": "Select a container app",
                "cookie_banner": "Show cookies banner (webapp)",
                "enable_category": "Enable category",
                "enable_google_calendar": "Enable Google calendar invitations",
                "force_anonymization": "Behavioural data anonymization",
                "force_anonymization_hint": "You have the flexibility to choose between customizing this setting individually or globally just for new workspaces. All new webinars and Content Hubs users data will be always anonymized by default.",
                "force_anonymization_off": "Per workspaces",
                "force_anonymization_off_hint": "during workspace creation, you can decide whether to anonymize data and apply it to all users.",
                "force_anonymization_on": "All workspaces",
                "force_anonymization_on_hint": "all or selected users of new workspaces will be anonymized.",
                "privacy_documents": "Privacy policies",
                "privacy_documents_placeholder": "Choose polices",
                "servers_location": "Data location",
                "servers_location_placeholder": "Select a server location",
                "show_access_as": "Allow impersonating users",
                "show_access_as_hint": "Show the \"Access As ...\"  button in user profile and export the impersonating link in the XLS file.",
                "timezone": "Time zone",
                "timezone_placeholder": "Time zone",
                "unrestricted_workspace_duration": "Do not restrict workspace duration",
                "update_success": "Organization data saved"
            }
        }
    },
    "organizations": {
        "entitlements": {
            "plans": {
                "expires": "Expires",
                "id": "ID",
                "title": "Active plans",
                "types": {
                    "package": "tokens",
                    "subscription": "seats"
                },
                "usage": "You have {0} {1} out of {2} left"
            }
        }
    }
}
